<template>
    <div class="form-text-input" v-bind="$attrs">
        <label class="p-field-label" v-if="label" :for="getName" v-html="label"></label>
        <span :class="{ 'p-input-icon-left': slots.default, 'p-field-has-overlay': slots.overlay, 'p-inputgroup': slots.suffix }" class="text-field-input">
            <i class="slotted-icon" v-if="slots.default"><slot></slot></i>
            <slot name="overlay"></slot>

            <Input-Text v-if="!mask" :readOnly="isReadOnly" ref="inputRef" :disabled="disabled" v-bind="extraProps" :autocomplete="autocomplete" :id="id" :name="getName" :type="type" v-model="inputValue" :placeholder="placeholder" :class="[ inputClass, { 'p-invalid': isInvalid }]" @focus="onFocus" @blur="onBlur"></Input-Text>

            <Input-Mask v-if="mask" ref="inputRef" :disabled="disabled" v-bind="extraProps" :autocomplete="autocomplete" :id="id" :name="getName" :type="inputType" v-model="type" :placeholder="placeholder" :class="[ inputClass, { 'p-invalid': isInvalid }]" @blur="onBlur"></Input-Mask>

            <span class="p-inputgroup-addon" v-if="slots.suffix">
                <slot name="suffix"></slot>
            </span>
        </span>
        <span class="form-error-text" v-if="error" severity="error" >{{error.$message}}</span>
    </div>
</template>
<script setup>
    import { computed, onMounted, ref, useSlots, watch } from 'vue'

    const props = defineProps({
        modelValue: {
            default: null
        },
        name: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        inputClass: {
            type: String,
            default: ''
        },
        suffix: {
            type: [Boolean, String],
            default: false
        },
        disabled: {
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        validator: {
            type: Object,
            default: null
        },
        error: {
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        silent: {
            type: Boolean,
            default: false
        },
        defaultClass: {
            default: 'p-field p-col'
        },
        autocomplete: {
            default: null
        },
        mask: {
            type: [String, Boolean],
            default: false
        },
        fieldType: {
            type: String,
            default: null
        },
        noFill: {
            type: Boolean,
            default: false
        }
    })

    const emit = defineEmits(["update:modelValue", "blur"])
    const $v = props.validator;

    const slots = useSlots();

    const isReadOnly = ref(false);
    const firstFocus = ref(false)
    const isReady = ref(false);

    const componentType = computed(() => {
        return props.mask ? 'Input-Mask' : 'Input-Text'
    })

    const getName = computed(()=> props.name || $v.path)

    const error = computed(() => {
        if ( props.error ) {
            //console.log(props.error)
            return props.error.length ? props.error[0] : null
        }
        if ( !$v ) return null;

        return $v.$dirty && $v.$error ? $v.$errors[0] : false

    })
    const isInvalid = computed(() => {
        if ( props.error ) {
            //console.log(props.error)
            return props.error.length ? true : null
        }
        if ( !$v ) return null;
        return $v.$dirty && $v.$error
    })

    const onFocus = (event) => {
        if ( !firstFocus.value ) {
            firstFocus.value = true

        }

    }

    const onBlur = (event) => {
        if ( !props.silent && $v ) {
            $v.$touch();
        }
        //console.log('evebt', event)
        emit('blur', inputValue.value)
    }



    const extraProps = computed(() => {


        if ( props.mask ) {
            return {
                mask: props.mask,
                placeholder: props.mask
            }
        } else {
            return {
                placeholder: props.placeholder
            }
        }

    })



    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })



    const inputRef = ref()
    const focus = () => {
        inputRef.value.focus()
    }

    const inputType = ref('text')





    if ( props.noFill ) {
        isReadOnly.value = true
    }



    onMounted( () => {
        isReady.value = true
        if ( props.noFill ) {
            setTimeout(
                () => {
                    isReadOnly.value = false

                },
                1000
            )
        }
    })

    defineExpose({
        focus
    })

</script>
<script>
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
export default {
    name: "TextField",
    components: { InputText, InputMask },
};
</script>
<style lang="scss" scoped>
    .form-text-input {
        display: flex;
        flex-direction: column;
    }
    .p-field-has-overlay {
        position: relative;
        display: block;
        input {
            padding-right: 40px;
        }
    }
    .p-inputgroup-addon {
        flex-shrink: 0;
        font-weight: 600;
        padding: 0 1rem;
    }
    .p-input-icon-left .p-inputtext {
            padding-left: 1.95rem!important;
    }
</style>
