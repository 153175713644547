<template>
    <div :class="{'layout-sidebar': !pageProps.hideMenu, 'content-loading': contentLoading }" class="layout-wrapper layout-slim layout-menu-light layout-topbar-light" data-theme="light">


        <div class="layout-splash-elements" :class="{ 'bg-uploader-active': formData }">

                <!--<transition name="simple-fade">
                    <div class="bg-uploader-container" v-show="pageProps.useBgUploader">
                    </div>
                </transition>-->

                <transition name="simple-fade">
                <div class="bg-uploader-container" v-if="formData">
                    <BgUploader
                        ref="uploader"
                        name="upload"
                        :aspect-ratio="10 / 11"
                        preview-width="100%"
                        preview-height="100%"
                        preview-padding="0px"
                        :min-width="1024"
                        :min-height="768"
                        extra-instructions="1440px+ recommended."
                        label="Image"
                        :error="formData.error"
                        error-message="A image upload is required"
                        :image="formData.form.image"
                        :choose-label="formData.form.image || formData.form.upload ? 'Change Image' : 'Add Image'" @added-image="(upload) => formData.form.upload = upload">
                    </BgUploader>
                </div>
                </transition>

                <div class="layout-splash-image">

                    <transition name="fade">
                        <img v-if="!backgroundImages.length && !formData" src="@/images/splash.jpg" alt="" />
                    </transition>

                    <transition-group name="fade">
                        <img v-show="image == bgSample" v-for="(image, idx) in backgroundImages" :key="'image-'+idx" :src="image" />
                    </transition-group>


                </div>

        </div>


        <div class="layout-content-wrapper" :class="[ pageProps.layoutClass, { 'wide-layout': pageProps.wideLayout } ]">



            <DashboardSidebar></DashboardSidebar>

            <div class="layout-main" :class="pageProps.layoutMainClasses">


                <div class="layout-content" :class="pageProps.layoutContentClasses">

                    <slot/>

                </div>
            </div>



        </div>

        <div class="welcome-text-bar">
            <span>Welcome back, <strong v-text="user.first_name"></strong></span>
            <div class="user-settings">
                <Link class="user-settings-link" href="/logout">
                    <span class="iconify" data-icon="ph:sign-out-bold"></span>
                </Link>
            </div>
        </div>



        <ConfirmModal ref="modalRef"></ConfirmModal>
        <modals-container></modals-container>

        <Head>
            <title>{{ pageTitle }}</title>
        </Head>

    </div>
</template>
<script setup>


    import { computed, ref, provide, onMounted, defineComponent, onActivated, inject, watch, nextTick, defineAsyncComponent, reactive } from 'vue'
    import { usePage, Head } from '@inertiajs/inertia-vue3'
    import { Inertia } from '@inertiajs/inertia'
    import { pluck, flatten, sample } from 'underscore'
    //import { useToast } from "vue-toastification";

    const modalRef = ref()
    const $modal = reactive({
        show: (opts) => modalRef.value.show(opts),
        hide: () => modalRef.value.hide()
    })
    provide('$modal', $modal)


    const layoutLoaded = inject('layoutLoaded', false)

    const formData = ref(null)

    provide('layoutFormData', formData)


    const DashboardSidebar = defineAsyncComponent(() => import('./Partials/DashboardSidebar.vue'))

    const sidebarActive = ref(false)
    provide('sidebarActive', sidebarActive)



    const pageProps = usePage().props;
    const curPage = usePage().component;

    const user = computed( () => usePage().props.value.user )
    const accounts = computed( () => usePage().props.value.accounts )
    const curRoute = computed(() => usePage().url.value)
    //const page = usePage()


    const pageTitle = computed( () => {
        return !!pageProps.value.pageTitle ? `${pageProps.value.pageTitle} | WonderGuides` : `WonderGuides`
    });

    const contentLoading = ref(false)


    const uploader = ref()
    provide('uploader', uploader)




    const backgroundImages = ref([])

    backgroundImages.value = flatten(accounts.value.map( (account) => {
        if (!account.display.account || !account.display.account.backgrounds) return []
        return account.display.account.backgrounds.map( (bg) => bg.src )
    }) )

    const bgSample = ref(null)

    const resample = () => {
        if ( backgroundImages.value.length ) {
            bgSample.value = sample(backgroundImages.value)
        }
    }
    resample()



    Inertia.on('start', (event) => {

        contentLoading.value = true

    })

    Inertia.on('finish', (event) => {
        contentLoading.value = false
        resample()
    })

    onMounted(() => {
        layoutLoaded.value = true
    })


</script>
<style lang="scss">

    .welcome-text-bar {
        position: relative;
        .user-settings {
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            a {
                color: #fff;
                font-size: 20px;
                width: 34px;
                height: 34px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

</style>
