<template>
    <transition name="multi-button">
        <div @trigger="clickedOutside" class="multi--menu-wrapper" v-if="selection.length" ref="toggleParent">


            <Button class="p-button-sm p-button-muted" type="button" label="Toggle" @click="toggleMenu" >
                <i class="p-button-icon-big">
                <span class="iconify row-icon" data-icon="ph:caret-down-fill"></span>
                </i>
                <span class="p-button-label">Multiselect Actions</span>
            </Button>
            <Menu ref="multiRef" :popup="true" class="row-menu" icon="pi pi-plus" :model="actions">
                <template #item="{item}">
                        <a class="p-menuitem-link" @click="triggerAction(item)">
                            <span class="p-menuitem-icon iconify" :data-icon="item.icon"></span>
                            <span class="p-menuitem-text" v-text="item.label"></span>
                        </a>
                </template>
            </Menu>


        </div>
    </transition>
</template>
<script setup>



    import { useToggle } from '@vueuse/core';
    import { computed, onMounted, ref } from 'vue'


    const props = defineProps({
        actions: {
            default: []
        },
        selection: {
            default: []
        },
    })

    const multiActions = () => props.actions

    const multiRef = ref(null)
    const menuOpen = ref(false)

    const toggleParent = ref(null)

    const toggleMenu = (event) => {
        multiRef.value.toggle(event)
    }

    const triggerAction = (item) => {
        item.action( props.selection )
        multiRef.value.hide()
    }




</script>
