<template>
    <div class="block-grid editor-block" :style="gridVars">



        <GridColumn v-model="grid.columns[index]" v-for="(column, index) in modelValue.columns" :key="index">
        </GridColumn>


    </div>
</template>
<script setup>

    import { reactive, ref, computed, onMounted } from 'vue'
    //url, requiredIf, requiredUnless
    import { helpers, minLength, required } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'
    import { useDebounce } from '@vueuse/shared'



    import GridColumn from './Partials/GridColumn.vue'

    const props = defineProps({
        modelValue: {
            type: Object,
        },
    })

    const emit = defineEmits(['update:modelValue'])


    const hoveringBlock = ref(false)
    const hoveringBlockDebounced = useDebounce(hoveringBlock, 100)

    const hoveringGrid = ref(false)
    const hoveringGridDebounced = useDebounce(hoveringGrid, 100)


    const grid = computed({
        get: () => props.modelValue,
        set: (value) => {
            emit('update:modelValue', value)
        }
    })

    const gridVars = computed(() => {

        let opts = {}

        //opts.maxWidth = grid.value.maxWidth+'px'
        opts.gridTemplateColumns = grid.value.span
        opts.gap = grid.value.gap+'px'
        //opts.marginTop = grid.value.margin.top
        //opts.marginBottom = grid.value.margin.bottom

        return opts

    })




    /*const rules =
        computed( () =>
        ({

           // title: { required: helpers.withMessage('A title is required', and(required, minLength(2) ) ) },

        }))

    const $v = useVuelidate(rules, grid, { $registerAs: 'grid' })*/

</script>
<style lang="postcss" scoped>

    .block-grid {
        display: grid;
        margin-left: auto;
        margin-right: auto;

    }




</style>
