<template>
    <vue-final-modal v-model="showModal" :click-to-close="false" :esc-to-close="false" @closed="closed" @opened="opened" name="ewVenueModal" classes="modal-container" transition="modal-open">

        <div class="modal__content" v-if="showModal">
            <div class="video-modal-container">

                <Button class="p-button-icon-only p-button-danger close-button" @click="hide">
                    <span class="iconify p-button-icon" data-icon="ph:x-bold"></span>
                </Button>

                <div class="video-modal-content">

                    <div class="form-field video-field">


                        <text-field type="text" label="Video URL" :disabled="videoObject" name="pending_url" v-model="pendingUrl" placeholder="Paste a youtube or vimeo URL">
                            <template #overlay>
                                <a v-if="videoObject" class="remove-video" @click="clearVideo"><span class="iconify" data-icon="ph:x-bold"></span></a>
                            </template>
                        </text-field>

                    </div>

                    <div v-if="videoObject" class="video-info">
                        <div class="video-thumbnail">
                            <img :src="videoObject.thumbnail" />
                        </div>
                        <div class="video-body">
                            <h3 v-html="videoObject.title"></h3>


                        </div>
                    </div>

                    <div v-if="videoObject" class="video-controls">
                        <Button class="p-button-sm p-button-success flex items-center" @click="save">
                            <span style="width: 1.2rem; height: 1.2rem" class="iconify p-button-icon" data-icon="ph:floppy-disk-bold"></span>
                            <span class="p-button-text">Add Video</span>
                        </Button>
                    </div>


                </div>
            </div>

        </div>

    </vue-final-modal>

</template>
<script setup>
    import { inject, reactive, ref } from "vue";

    import { debouncedWatch } from '@vueuse/core'
    import urlParser from "js-video-url-parser/lib/base";
    import "js-video-url-parser/lib/provider/vimeo";
    import "js-video-url-parser/lib/provider/youtube";

    const emit = defineEmits(['video-selected'])

    const axios = inject('axios')

    const googleApiKey = import.meta.env.VITE_GOOGLE_API_KEY

    const showModal = ref(false)


    const targetNode = ref(false)

    const opened = () => {

    }
    const closed = () => {

    }



    const pendingUrl = ref(null)
    const videoObject = ref(null)

    const parseUrl = (url) => {
        let res = urlParser.parse( url );

        if ( !!res ) {
            if ( res.provider == 'youtube' ) {
                res.thumbnail = `https://img.youtube.com/vi/${res.id}/mqdefault.jpg`

                axios
                    .get(`https://www.googleapis.com/youtube/v3/videos?id=${res.id}&part=snippet&key=${googleApiKey}`)
                    .then((response) => {
                        console.log("VIDEO RESPONSE", response)
                        if ( response.data.items.length ) {
                            let data = response.data.items[0].snippet
                            res.thumbnail = data.thumbnails.high.url
                            res.title = data.title
                            res.channel = data.channelTitle

                            videoObject.value = { ...res }
                        } else {
                            videoObject.value = null
                            pendingUrl.value = null
                        }
                    })


            } else if ( res.provider == 'vimeo' ) {

                axios
                    .get(`https://api.vimeo.com/videos/${res.id}?access_token=6233870667038c8ca055ad2cef10062d`)
                    .then((response) => {
                        console.log("VIDEO RESPONSE", response)

                        let data = response.data

                        let thumb = data.pictures.sizes.find( (size) => {
                            return size.width > 600 && size.width < 1000
                        })
                        res.thumbnail = thumb ? thumb.link :  data.pictures.base_link
                        res.title = data.name
                        videoObject.value = { ...res }

                    })
                    .catch( (response) =>{
                        console.log('error', response.data)
                        videoObject.value = null
                        pendingUrl.value = null
                    })

            }

        } else {
            videoObject.value = null
        }

    }

    debouncedWatch(
        () => pendingUrl.value,
        (newVal, oldVal) => {
            console.log('TEST!', pendingUrl.value)
            if ( !!newVal && newVal !== oldVal ) {
                console.log('um hello')
                parseUrl(newVal)
            }
        },
        { debounce: 500 }
    )

    const save = (event) => {
        emit('video-selected', videoObject, targetNode)
        hide()
    }

    const clearVideo = () => {
        pendingUrl.value = null
        videoObject.value = null
    }

    const show = (target) => {
        showModal.value = true
        targetNode.value = !!target ? target : false
    }

    const hide = () => {
        showModal.value = false
        targetNode.value = false
        pendingUrl.value = null
        videoObject.value = null
    }


    defineExpose({
        show,
        hide
    })
</script>

<style lang="scss" scoped>
    .video-controls {
        display: flex;
        justify-content: flex-end;
        .p-button {

        }
    }
    .form-field.video-field {
        position: relative;
        margin-bottom: 1rem;
        .remove-video {
            height: 35px;
            width: 35px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #B91D1B;
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
    ::v-deep(.modal-container) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ::v-deep(.modal-content) {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 90%;
        margin: 0 1rem;
        padding: 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        background: #fff;
    }
    .modal__title {
        margin: 0 2rem 0 0;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .modal__content {
        flex-grow: 1;
    }
    .modal__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem 0 0;
    }
    .modal__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
    .video-modal-container {
        border-radius: 6px 0 6px 6px;
        background: #ffffff;
        box-shadow: 0 0 4px rgba(0,0,0,.07), 1px 1px 10px rgba(0,0,0,.1);
        width: 380px;
        max-width: 100%;
        position: relative;
        .video-modal-content {
            padding: 1.4rem;
        }
        .close-button {
            position: absolute;
            transform: translateY(-100%);
            right: 0;
            border-radius: 6px 6px 0 0;
            padding: 0;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .autocomplete-preview {
        display: flex;
        align-items: center;
        max-width: 320px;
        white-space: normal;
        .autocomplete-thumbnail {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            border: 3px solid rgba(0,0,0,.1);
            margin-right: 10px;
            img {
                display: block;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .autocomplete-content {
            h3 {
                font-size: .85rem;
                margin: 0 0 2px 0;
                font-weight: 600;
            }
            h4 {
                font-size: .7rem;
                font-weight: 500;
                color: #888;
                margin: 0 0 5px 0;
                font-style: italic;
            }
            p {
                margin: 0;
                font-size: .875rem;
            }
        }
    }
    .video-info {
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 1px 1px rgba(0,0,0,.1), 0 2px 8px rgba(0,0,0,.15);
        background: #fefefe;
        .video-thumbnail {
            width: 100%;
            img {
                width: 100%;
                height: 170px;
                object-fit: cover;
                display: block;
            }
        }
        .video-body {
            padding: 1rem;
            h3 {
                margin: 0;
                font-size: .9rem;
            }
        }
    }
    .video-controls {
        margin-top: 18px;
    }
</style>
