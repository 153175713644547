<template>
    <div v-bind="$attrs">
        <label class="p-field-label" :for="getName" v-html="label"></label>
        <span :class="{ 'p-input-icon-left': slots.default }">

            <slot></slot>

            <component :is="componentType" :autocomplete="autocomplete" :id="getName" :name="getName" :type="type" v-model="inputValue" :class="{ 'p-invalid': isInvalid }"></component>

        </span>
        <span class="form-error-text" v-if="error" severity="error" :key="error.$uid">{{error.$message}}</span>
    </div>
</template>
<script setup>
    import { computed, ref, useSlots, watch } from 'vue'

    const props = defineProps({
        modelValue: {
            default: null
        },
        name: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        validator: {
            type: Object,
            default: null
        },
        silent: {
            type: Boolean,
            default: false
        },
        defaultClass: {
            default: 'p-field p-col'
        },
        autocomplete: {
            default: null
        },
        mask: {
            type: [String, Boolean],
            default: false
        }
    })

    const emit = defineEmits(["update:modelValue"])
    const $v = props.validator;

    const slots = useSlots();

    const componentType = computed(() => {
        return props.mask ? 'Input-Mask' : 'Input-Text'
    })

    const getName = computed(()=> props.name || $v.path)

    const error = computed(() => {
        if ( !$v ) return null;
        return $v.$dirty && $v.$error ? $v.$errors[0] : false

    })
    const isInvalid = computed(() => {
        if ( !$v ) return null;
        return $v.$dirty && $v.$error
    })

    const onBlur = () => {
        if ( !props.silent && $v ) {
            $v.$touch();
        }
    }



    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

</script>
<script>
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
export default {
    name: "TextField",
    components: { InputText, InputMask },
};
</script>
