<template>
    <div class="related-items-wrapper">


        <div class="related-sections-drag-wrapper">

            <!--<related-content-section v-model="modelValue[sdx]" @removeSection="removeSection(sdx)" v-for="(section, sdx) in modelValue" :key="sdx"></related-content-section>-->

            <draggable
                :list="inputValue"
                group="sections"
                class="related-sections"
                handle=".drag-handle"
                @start="enableDrag"
                @end="disableDrag"
                item-key="uuid">
                <template #item="{element, index}">




                    <related-content-section v-model="inputValue[index]" :uuid="element.uuid" @removeSection="removeSection(index)" :key="index">
                        <template #sort>
                            <a class="handle drag-handle">
                                <span class="iconify" data-icon="ph:dots-six-bold"></span>
                            </a>
                        </template>
                    </related-content-section>
                </template>

            </draggable>
            <!--<div class="related-section" v-for="(section, sdx) in modelValue" :key="sdx">

                <a class="remove-section" @click="removeSection(sdx)">
                    <span class="iconify" data-icon="ph:x-bold"></span>
                </a>

                <div class="p-fluid p-formgrid p-grid">
                    <TextField class="p-field p-col" label="Section Title" v-model="section.title" name="title"  placeholder="Enter a title">
                    </TextField>
                </div>



                <div class="item-blocks">

                    <related-content-item @removeBlock="removeBlock(section, bdx)" v-model="section.items[bdx]" v-for="(block, bdx) in section.items" :key="bdx"></related-content-item>

                    <div class="item-block" v-for="(block, bdx) in section.items" :key="bdx">
                        <span class="item-type" v-text="block.type"></span>
                        <a class="remove-item" @click="removeBlock(section, bdx)">
                            <span class="iconify" data-icon="ph:x-bold"></span>
                        </a>
                        <div class="item-image">
                            <UploadCrop
                                :ref="(el) => setUploaderRef(el, sdx, bdx)"
                                name="upload"
                                :aspect-ratio="false"
                                preview-width="100%"
                                preview-padding="0"
                                :image="block.image"
                                v-model="block.image"
                                label="Image"
                                error-message="A image upload is required"
                                :choose-label="false"
                                upload-url="/admin/upload/single"
                                >
                            </UploadCrop>
                        </div>

                        <div class="item-body">
                            <Textarea class="block-title" placeholder="Enter a title" v-model="block.title" rows="1" :autoResize="true"  />

                            <div class="block-field" v-if="block.type == 'custom'">
                                <TextField class="block-input p-inputtext-sm" label="Enter a url" v-model="section.url" name="url" placeholder="Enter a url">
                                    <span class="iconify" data-icon="ph:link-bold"></span>
                                </TextField>
                            </div>
                            <div class="event-dates" v-if="block.type == 'event' && block.object">
                                {{ block.object.next_date }}
                            </div>
                            <div class="venue-location" v-if="block.type == 'venue' && block.object">
                                {{ block.object.city }}, {{ block.object.state }}
                            </div>
                            <div class="post-author" v-if="block.type == 'post' && block.object">
                                <template v-if="block.object.first_name">
                                <i>by</i> {{ block.object.first_name }} {{ block.object.last_name }}
                                </template>
                                <template else>
                                    {{ block.object.city }}, {{ block.object.state }}
                                </template>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="block-add-wrapper">
                    <Dropdown :options="blockTypes" placeholder="Add a Block" @change="addBlock($event, section)">
                        <template #option="slotProps">
                            Add {{ slotProps.option.label }}
                        </template>
                    </Dropdown>
                </div>
            </div>-->

        </div>


        <div class="section-add-wrapper">
            <Button class="p-button-rounded" @click="addSection">
                <span class="iconify add-icon" data-icon="ph:plus-circle-bold"></span>
                <span class="p-button-text">Add Section</span>
            </Button>
            <Button class="p-button-rounded" @click="addExisting">
                <span class="iconify add-icon" data-icon="ph:plus-circle-bold"></span>
                <span class="p-button-text">Add from Existing</span>
            </Button>
        </div>


        <teleport to="body">
            <ew-related-modal ref="relatedModal" @selected="existingSelected"></ew-related-modal>
        </teleport>


    </div>
</template>
<script setup>
    import { ref, reactive, computed, inject, provide } from 'vue'
    //import blockTypes from './blockTypes.js'
   //import { format, parseJSON, add } from 'date-fns'
    import { helpers, minLength, required } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'

    //import RelatedContentItem from './RelatedContentItem.vue'

    import EwRelatedModal from '../Modals/EwRelatedModal.vue'

    import RelatedContentSection from './RelatedContentSection.vue'

    const emit = defineEmits(['update:modelValue'])

    const relatedModal = ref();

    const chance = inject('chance')

    const draggingBlock = ref(false);
    provide('draggingBlock', draggingBlock)

    const enableDrag = () => {
        draggingBlock.value = true
    }
    const disableDrag = () => {
        setTimeout( () => {
            draggingBlock.value = false
        }, 400)
    }

    //const ewPrefix = 'https://api.everwondr.com'

    const props = defineProps({
        modelValue: {
            type: Array,
            default: []
        }
    })


    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const existingSelected = (existing) => {
        console.log('EXISTING', existing)
        existing.forEach( (section) => {
            inputValue.value.push( section )
        })

    }

    const addExisting = () => {
        relatedModal.value.show()
        console.log('RELATED MODAL', relatedModal.value)
    }

    const addSection = () => {
        inputValue.value.push( {
            title: '',
            uuid: chance.string({length: 12}),
            items: []
        } )
    }

    const removeSection = (index) => {

        inputValue.value.splice(index, 1)

    }

    const rules =
        computed( () =>
        ({
            /*$each: helpers.forEach({
                title: { required: helpers.withMessage('A section title is required', required) },
                items: { required: helpers.withMessage('A section must contain at least one item', required), minLength: helpers.withMessage('A section must contain at least one item', minLength(1))  }
            })*/
        }))

    const $v = useVuelidate(rules, inputValue, { $registerAs: 'related_items'})


</script>
<style lang="scss">
.related-items-wrapper {

    .event-dates, .venue-location, .post-author, .video-link {
        border-radius: 4px;
        background: #fafafa;
        display: inline-block;
        font-size: .85rem;
        font-weight: 600;
        padding: 3px 7px;
        box-shadow: 0 1px 2px rgba(0,0,0,.15);
        margin-top: 1rem;
        i {
            color: #999;
        }
    }
    .video-link {
        display: flex;
        align-items: center;
        a {
            color: #69707A;
            &:hover {
                color: #03A2DC;
            }
        }

        svg {
            margin-right: 5px;
            font-size: 18px;
        }
    }
    .section-add-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
        gap: 10px;
        .p-button {
            display: flex;
            align-items: center;
            .iconify {
                margin-right: 4px;
            }
        }
    }
    .no-image-container {
        background: #efefef;
        height: 90px;
        border-radius: 5px;
    }
    .block-field {

        .block-input {
            width: 100%;
            display: flex;
            flex-direction: column;
            label {
                font-size: .85rem;
                font-weight: 600;
            }
            .text-field-input {
                input {
                    width: 100%;
                }
            }
            .p-input-icon-left > .p-inputtext {
                padding-left: 1.95rem;
            }
        }
    }

    .block-add-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        .p-button {
            display: flex;
            align-items: center;
            .iconify {
                margin-right: 4px;
            }
        }
        .p-dropdown {
            border: none;
            background: #f5f5f5;
            //color: #fff!important;
            width: 150px;
            border-radius: 15px;
            &:focus-within{
                box-shadow: 0 0 1px 1px rgba(0,0,0,.1);
                .p-dropdown-trigger[aria-expanded="true"] {
                    .p-dropdown-trigger-icon {
                        transform: rotate(180deg);
                    }
                }
            }
            .p-dropdown-label {
                //color: #fff!important;
                font-size: .875rem;
                font-weight: 600;
            }
            .p-dropdown-trigger {
                //color: #fff!important;
                .p-dropdown-trigger-icon {

                    transition: transform .3s ease;
                }
            }
            .p-inputtext {
                padding: 0 1rem;
                height: 30px;
                line-height: 30px;
                font-weight: 600;
            }
        }
    }
    .related-sections {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        .related-section {
            border-radius: 6px;
            padding: 2.6rem 1.2rem 2.4rem;
            box-shadow: 0 0 3px rgba(0,0,0,.15), 0 2px 12px rgba(0,0,0,.07);
            position: relative;
            .remove-section {
                position: absolute;
                font-size: 16px;
                width: 24px;
                height: 24px;
                cursor: pointer;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #B91D1B;
                transition: background .3s ease;
                &:hover {
                    background: lighten(#B91D1B, 5%);
                }
                color: #fff;
                z-index: 2;
                top: 3px;
                right: 3px;
            }
        }
    }
    .item-blocks {
        display: grid;
        gap: 1.2rem;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;
        flex-wrap: wrap;

        .item-block {
            border-radius: 6px;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            box-shadow: 0 1px 2px rgba(0,0,0,.1), 0 0 15px rgba(0,0,0,.06);
            .item-type {
                position: absolute;
                left: 3px;
                top: 3px;
                border-radius: 3px;
                background: #ffffff;
                font-size: 10px;
                text-transform: uppercase;
                z-index: 2;
                font-weight: 600;
                padding: 4px;
            }
            .remove-item {
                position: absolute;
                font-size: 16px;
                width: 24px;
                height: 24px;
                cursor: pointer;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #B91D1B;
                transition: background .3s ease;
                &:hover {
                    background: lighten(#B91D1B, 5%);
                }
                color: #fff;
                z-index: 2;
                top: 3px;
                right: 3px;
            }
            .item-image {
                width: 100%;
                height: auto;
                .image-cropper-field {
                    width: 100%;
                    margin: 0!important;
                    .cropper-controls-wrapper {
                        width: 100%;
                        .image-cropper-preview {
                            width: 100%;
                            height: 90px;
                            img {
                                display: block;
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
            .item-body {

                padding: 15px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                h4 {
                    font-size: 1rem;
                    margin: 0 0 5px;
                }
                .block-title {
                    padding: 10px;
                    background: #fafafa;
                    border: 0;
                    box-shadow: inset 0 -1px 1px 1px rgba(0,0,0,.03);
                    font-size: 1rem;
                    margin: 0 0 5px;
                    font-weight: 600;
                    width: 100%;
                    &.p-invalid {
                        border: 1px solid #F44337!important;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: inset 0 0 5px rgba(0,0,0,.12);
                    }
                }
                .video-link {

                }
            }
        }
    }
}
</style>
