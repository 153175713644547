<template>
    <div class="row-selector">

                <h4>Add Row:</h4>
                <a class="add-block" @click="addRow('html')" v-tooltip.top="'Add Simple Editor'">
                    <span class="iconify" data-icon="ph:article-medium-bold"></span>
                    <span class="plus-icon">
                        <span class="plus iconify" data-icon="fa-solid:plus"></span>
                    </span>
                </a>
                <a class="add-block" @click="addRow('grid')" v-tooltip.top="'Add Grid'">
                    <span class="iconify" data-icon="clarity:image-gallery-solid"></span>
                    <span class="plus-icon">
                        <span class="plus iconify" data-icon="fa-solid:plus"></span>
                    </span>
                </a>
                <a class="add-block" @click="addRow('blockquote')" v-tooltip.top="'Add Blockquote'">
                    <span class="iconify" data-icon="ph:quotes-fill"></span>
                    <span class="plus-icon">
                        <span class="plus iconify" data-icon="fa-solid:plus"></span>
                    </span>
                </a>
                <!--<a class="add-block" @click="addBlock('grid')" v-tooltip.top="'Add Content Grid'">
                    <span class="iconify" data-icon="fe:columns"></span>
                    <span class="plus-icon">
                        <span class="plus iconify" data-icon="fa-solid:plus"></span>
                    </span>
                </a>
                <a class="add-block" @click="addBlock('video')" v-tooltip.top="'Add Video'">
                    <span class="iconify" data-icon="fe:columns"></span>
                    <span class="plus-icon">
                        <span class="plus iconify" data-icon="fa-solid:plus"></span>
                    </span>
                </a>-->


                <teleport to="body">

                    <grid-selector ref="gridSelector" @insert="insertGrid"></grid-selector>

                </teleport>

    </div>
</template>
<script setup>
    import { ref } from 'vue'
    const emit = defineEmits(['insert'])

    import GridSelector from '../Modals/GridSelector.vue'

    const blockSchema = {
        html: {
            type: 'html',
            content: ''
        },
        blockquote: {
            type: 'blockquote',
            content: ''
        },
        grid: {
            type: 'grid',
            settings: {
                padding: '0px',
                gap: '1.5rem',
                maxWidth: 700,
                margin: '1.5rem auto',
                widths: [ '1']
            },
            columns: [
                {
                    styles: {

                    },
                    blocks: {

                    }
                }
            ]
        },
        /*video: {
            type: 'video',
            settings: {
                padding: '0px',
                maxWidth: '80%',
                margin: '1.5rem auto',
                justify: 'center'
            },
            source: null,
            provider: null
        },
        image: {
            type: 'video',
            settings: {
                padding: '0px',
                maxWidth: '80%',
                margin: '1.5rem auto',
                justify: 'center'
            },
            source: null,
            provider: null
        }*/
    }

    const gridSelector = ref()

    const addRow = (type) => {
        if ( type == 'html') emit('insert', { ...blockSchema.html })
        if ( type == 'grid' ) gridSelector.value.show()
        if ( type == 'images' ) {
            let schema = { ...blockSchema.grid }
            schema.settings.showImages = true
            emit('insert', { ...schema })
        }
        if ( type == 'video' ) emit('insert', { ...blockSchema.video })
        if ( type == 'blockquote' ) emit('insert', { ...blockSchema.blockquote })

    }

    const insertGrid = (grid) => {
        emit('insert', { ...grid } )
    }

    defineExpose({

    })
</script>
<style lang="postcss" scoped>


    .row-selector {
        display: flex;
        justify-content: center;
        align-items:center;
        margin: 1.4rem auto 0;
        width: 100%;
        gap: .5rem;
        &> h4 {
            margin: 0 5px 0 0;
            font-size: .8rem;
        }
        .add-block {
            display: flex;
            width: 35px;
            height: 35px;
            justify-content: center;
            align-items: center;
            background: #eee;
            border-radius: 50%;
            color: #888;
            font-size: 16px;
            position: relative;

            &:hover {
                .plus-icon {
                    color: #188ABF;
                }
            }

            .plus-icon {
                position: absolute;
                color: #888;
                background: #eee;
                border-radius: 50%;
                font-size: 8px;
                width: 12px;
                height: 12px;
                bottom: 5px;
                right: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: color .3s ease;

            }
        }
    }
</style>
