<template>
    <div v-bind="$attrs" class="flex-field">
        <label class="p-field-label" :for="getName" v-html="label"></label>
        <span :class="{ 'p-input-icon-left': slots.default, 'p-field-has-overlay': slots.overlay, 'p-inputgroup': slots.suffix }" class="text-field-input">
            <i class="slotted-icon" v-if="slots.default"><slot></slot></i>
            <slot name="overlay"></slot>

            <Textarea class="textarea-block" ref="inputRef" :disabled="disabled" v-bind="extraProps" :autoResize="autoResize" :autocomplete="autocomplete" :id="getName" :name="getName" :type="type" v-model="inputValue" :placeholder="placeholder" :class="[ inputClass, { 'p-invalid': isInvalid }]" :rows="rows" @blur="onBlur"></Textarea>

            <span class="p-inputgroup-addon" v-if="slots.suffix">
                <slot name="suffix"></slot>
            </span>

        </span>
        <span class="form-error-text" v-if="error" severity="error" >{{error.$message}}</span>
    </div>
</template>
<script setup>
    import { computed, ref, useSlots, watch } from 'vue'

    const props = defineProps({
        modelValue: {
            default: null
        },
        name: {
            type: String,
            default: null
        },
        rows: {
            default: 1
        },
        autoResize: {
            default: true
        },
        inputClass: {
            type: String,
            default: ''
        },
        suffix: {
            type: [Boolean, String],
            default: false
        },
        disabled: {
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        validator: {
            type: Object,
            default: null
        },
        error: {
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        silent: {
            type: Boolean,
            default: false
        },
        defaultClass: {
            default: 'p-field p-col'
        },
        autocomplete: {
            default: null
        },
    })

    const emit = defineEmits(["update:modelValue", "blur"])
    const $v = props.validator;

    const slots = useSlots();

    const getName = computed(()=> props.name || $v.path)

    const error = computed(() => {
        if ( props.error ) {
            //console.log(props.error)
            return props.error.length ? props.error[0] : null
        }
        if ( !$v ) return null;

        return $v.$dirty && $v.$error ? $v.$errors[0] : false

    })
    const isInvalid = computed(() => {
        if ( props.error ) {
            //console.log(props.error)
            return props.error.length ? true : null
        }
        if ( !$v ) return null;
        return $v.$dirty && $v.$error
    })

    const onBlur = (event) => {
        if ( !props.silent && $v ) {
            $v.$touch();
        }
        //console.log('evebt', event)
        emit('blur', inputValue.value)
    }

    const extraProps = computed(() => {


        if ( props.mask ) {
            return {
                mask: props.mask,
                placeholder: props.mask
            }
        } else {
            return {}
        }

    })



    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const inputRef = ref()
    const focus = () => {
        inputRef.value.focus()
    }

    defineExpose({
        focus
    })

</script>
<script>
import Textarea from 'primevue/textarea'
export default {
    name: "Textarea",
    components: { Textarea },
};
</script>
<style lang="scss" scoped>

    .p-field-has-overlay {
        position: relative;
        display: block;
        input {
            padding-right: 40px;
        }
    }
    .p-inputgroup-addon {
        flex-shrink: 0;
        font-weight: 600;
        padding: 0 1rem;
    }
    .flex-field {
        display: flex;
        flex-direction: column;
        textarea {
            width: 100%;
        }
    }

</style>
