<template>
    <div class="grid-item" :class="{ 'has-items': column.blocks.length, 'has-error': $v.blocks.$error }">





            <draggable
            :list="blocks"
            group="blocks"
            class="blocks"
            handle=".move-block"
            ghost-class="ghost-block"
            @start="enableDrag"
            @end="disableDrag"
            item-key="uuid">
                <template #header>
                    <div class="error-message" v-if="$v.blocks.$error">At least one block is required.</div>
                </template>
                <template #item="{element: block, index: blockIndex}">
                    <div class="block">

                        <div class="block-controls">
                            <a class="move-block">
                                <span class="iconify" data-icon="ph:arrows-out-cardinal"></span>
                            </a>
                            <a class="remove-block" @click="removeBlock(blockIndex)">
                                <span class="iconify" data-icon="ph:x-bold"></span>
                            </a>
                        </div>
                        <div class="block-image" v-if="block.type == 'image'" @mouseleave="removeToggles">
                            <div class="image-controls">
                                <div class="edit-height">
                                    <a class="edit-height-icon" @click.stop="toggleHeight($event)">
                                        <span class="iconify" data-icon="ph:frame-corners"></span>
                                    </a>
                                    <div class="edit-height-field">
                                        <InputNumber input-class="p-inputtext-sm" v-model="block.height" suffix="px" />
                                    </div>
                                </div>
                                <div class="edit-link">
                                    <a class="edit-link-icon" @click.stop="toggleLink($event)">
                                        <span class="iconify" data-icon="ph:link-bold"></span>
                                    </a>
                                    <div class="edit-link-field">

                                        <InputText type="text" class="p-inputtext-sm" v-model="block.link" placeholder="Image Link" />
                                    </div>
                                </div>
                            </div>
                            <UploadCrop
                                name="upload"
                                :aspect-ratio="false"
                                preview-width="100%"
                                :height="`${block.height}px`"
                                preview-padding="0"
                                :image="block.image"
                                v-model="block.image"
                                label="Image"
                                error-message="A image upload is required"
                                :choose-label="false"
                                upload-url="/admin/upload/single/1000"
                                >
                            </UploadCrop>
                        </div>

                        <div class="block-text" v-if="block.type == 'text'">
                            <AirEditor :disable-edit="draggingBlock" padding=".3rem 0" v-model="block.body"></AirEditor>
                        </div>

                    </div>
                </template>
            </draggable>





        <div class="add-options">
            <a class="add-block" @click="addBlock('image')" v-tooltip.top="'Add Image Block'">
                <span class="iconify" data-icon="ph:image-fill"></span>

                <span class="plus-icon">
                    <span class="iconify" data-icon="fa-solid:plus"></span>
                </span>
            </a>
            <a class="add-block" @click="addBlock('text')" v-tooltip.top="'Add Text Block'">
                <span class="iconify" data-icon="ph:article-medium-bold"></span>
                <span class="plus-icon">
                    <span class="iconify" data-icon="fa-solid:plus"></span>
                </span>
            </a>
        </div>

    </div>
</template>
<script setup>
    import { ref, computed, inject } from 'vue'
    import { helpers, required } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({
        modelValue: {
            default: {}
        }
    })

    const draggingBlock = inject('draggingBlock')

    const enableDrag = () => {
        draggingBlock.value = true
    }
    const disableDrag = () => {
        setTimeout( () => {
            draggingBlock.value = false
        }, 400)
    }

    const chance = inject('chance')

    const removeToggles = (event) => {
        console.log('ELEMENT', event)
        event.target.querySelector('.edit-link').classList.remove('toggled')
        event.target.querySelector('.edit-height').classList.remove('toggled')
    }
    const toggleLink = (event) => {
        console.log('ELEMENT', event)
        event.target.closest('.image-controls').querySelector('.edit-height').classList.remove('toggled')
        event.target.closest('.edit-link').classList.toggle('toggled')
    }
    const toggleHeight = (event) => {
        console.log('ELEMENT', event)
        event.target.closest('.image-controls').querySelector('.edit-link').classList.remove('toggled')
        event.target.closest('.edit-height').classList.toggle('toggled')
    }

    const column = computed({
        get: () => props.modelValue,
        set: (value) => emit('update:modelValue', value)
    })

    const blocks = computed({
        get: () => column.value.blocks,
        set: (value) => column.value.blocks = value
    })

    const addBlock = (type) => {
        let uuid = chance.string({length: 12})
        if ( type == 'image' ) {
            column.value.blocks.push({
                type: 'image',
                uuid: uuid,
                link: '',
                image: '',
                height: 160
            })
        }
        if ( type == 'text' ) {
            column.value.blocks.push({
                type: 'text',
                uuid: uuid,
                body: ''
            })
        }
    }

    const removeBlock = (index) => {

        column.value.blocks.splice(index, 1)

    }

    const blockMove = (index, offset) => {

        let newIndex = index + offset

        if (newIndex > -1 && newIndex < column.value.blocks.length) {
            let removedElement = column.value.blocks.splice(index, 1)[0]
            column.value.blocks.splice(newIndex, 0, removedElement)
        }
    }

    const rules =
        computed( () =>
        ({

           blocks: { required: helpers.withMessage('At least one block is required', required ) },

        }))

    const $v = useVuelidate(rules, column)

</script>
<style lang="postcss">




    .grid-item {
        min-height: 50px;
        display: flex;
        flex-direction: column;
        gap: .8rem;
        position: relative;
        //padding: 8px;
        width: 100%;
        &:hover {

            box-shadow: 0 0 0 2px rgba(0,0,0,.03);
        }

         .has-error {
            box-shadow: inset 0 0 0 2px rgba(211, 47, 47, .1);
        }
        .error-message {
            color: #D32F2F;
            font-size: .8rem;
            font-weight: 600;
            text-align: center;
        }
        .blocks {
            display: grid;
            position: relative;
            gap: .8rem;
            min-height: 50px;
            .block {
                &.block-image {
                    padding: 8px;
                    position: relative;
                }
                .block-image {
                    .image-cropper-field {
                        margin-bottom: 0!important;
                    }
                }
                .block-image:hover .image-controls, .image-controls:focus-within {

                        opacity: 1;

                }
                .image-controls {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    transition: opacity .3s ease;
                    opacity: 0;
                    display: flex;
                    align-items: center;
                    padding: 3px;
                    gap: 4px;
                    &:hover {

                        .edit-link, .edit-height {
                            opacity: .7;
                        }

                    }
                    &:not(:hover) {
                        .edit-link:not(.toggled), .edit-height:not(.toggled) {
                            opacity: .6;
                        }
                    }
                    .edit-link {
                        transition: opacity .3s ease;


                        &.toggled {
                            opacity: 1;
                            .edit-link-field {
                                opacity: 1;
                                pointer-events: initial;
                            }
                        }

                        .edit-link-icon {
                            width: 24px;
                            height: 24px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #eee;
                            color: #666;
                            svg {
                                pointer-events: none;
                            }
                        }
                        .edit-link-field {
                            position: absolute;
                            right: 0;
                            pointer-events: none;
                            top: 100%;
                            opacity: 0;
                            transition: opacity .3s ease;
                            padding: 4px;
                            background: #eee;

                        }
                    }
                    .edit-height {
                        transition: opacity .3s ease;

                        &.toggled {
                            opacity: 1;
                            .edit-height-field {
                                opacity: 1;
                                pointer-events: initial;
                            }
                        }

                        .edit-height-icon {
                            width: 24px;
                            height: 24px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #eee;
                            color: #666;
                            svg {
                                pointer-events: none;
                            }
                        }
                        .edit-height-field {
                            position: absolute;
                            right: 0;
                            pointer-events: none;
                            top: 100%;
                            opacity: 0;
                            transition: opacity .3s ease;
                            padding: 4px;
                            background: #eee;
                            input {
                                height: 28px!important;
                                font-size: .9rem;
                            }
                        }
                    }
                }
                &.block-text {
                    ::v-deep(.ql-editor) {
                        padding: 8px!important;
                        min-height: 60px;
                    }
                }
                width: 100%;
                position: relative;
                .block-controls {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 40px;
                    padding: 3px;
                    gap: 5px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    opacity: 0;
                    transition: opacity .3s ease;
                    z-index: 5;
                }
                .remove-block {
                    width: 18px;
                    height: 18px;
                    font-size: 12px;

                    color: #fff;
                    background: #D32F2F;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    opacity: .6;
                    transition: opacity .3s ease;
                    cursor: pointer;

                    border-radius: 3px;
                }
                .move-block {
                    width: 18px;
                    height: 18px;
                    font-size: 12px;
                    color: #888;
                    background: #eee;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: opacity .3s ease, color .3s ease, background .3s ease;
                    opacity: .6;
                    cursor: pointer;
                    z-index: 5;
                    border-radius: 3px;

                    &:not(.disabled):hover {
                        color: #fff;
                        background: #188ABF;
                    }
                }

                &:hover {
                    box-shadow: inset 0 0 0 2px rgba(0,0,0,.05);
                    .block-controls {
                        opacity: 1;
                        .remove-block:hover, .move-block:hover {
                            opacity: 1;
                        }
                    }
                }
                ::v-deep(.image-cropper-field) {
                    padding: 0;
                    box-shadow: none;
                    margin: 0!important;
                    .no-image-container {
                        min-height: 100px;
                    }
                }
            }
        }
        &:hover {
            div.add-options {
                height: 42px;
                opacity: 1;
                transform: none;
                pointer-events: initial;
            }
        }

    }

    .has-items .add-options {
        opacity: 0;
        height: 0;
        transform: translateY(-20px);
        pointer-events: none;
    }
    .add-options {
        display: flex;
        gap: .6rem;
        justify-content: center;
        align-items: center;

        transition: transform .3s ease-out, opacity .3s ease-out, height .3s ease-out;
        .add-block {
            display: flex;
            color: #888;
            font-size: 14px;
            width: 28px;
            height: 28px;
            background: #eee;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            position: relative;
            .plus-icon {
                position: absolute;
                background: #eee;
                font-size: 6px;
                display: flex;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                bottom: 4px;
                right: 4px;
            }
        }
    }

</style>
