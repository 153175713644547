<template>
    <div class="image-cropper-field">

        <div class="image-cropper-element">
        <file-pond
            ref="pond"
            name="image"
            accepted-file-types="image/jpeg, image/png"
            :allow-multiple="false"
            :imageEditorInstantEdit="true"
            :imageEditor="imageEditor"
            :imageEditorAfterWriteImage="processFile"
            :files="fileArr"
            :server="serverOpts"
            :credits="null"
            @:init="handleFilePondInit"
        />
        </div>

    </div>
</template>


<script setup>
const props = defineProps({
  image: {
      type: Object,
  }
})
const emit = defineEmits(['update:modelValue', 'addedImage'])
const fileArr = ref([
   // {
   //     source: '/splash.jpg',
   // }

]);

const processFile = (res) => {
    console.log('____UPLOADED_____', res)
    return res.dest
}

const serverOpts = {
    server: './',
    process: {
        url: './api/image/upload',
        method: 'POST',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': usePage().props.value.csrf_token
        }
    },
}

const imageEditor = {
                // map legacy data objects to new imageState objects
                legacyDataToImageState: legacyDataToImageState,

                // used to create the editor, receives editor configuration, should return an editor instance
                createEditor: openEditor,

                // Required, used for reading the image data
                imageReader: [createDefaultImageReader],

                // optionally. can leave out when not generating a preview thumbnail and/or output image
                imageWriter: [createDefaultImageWriter],

                // used to generate poster images, runs an editor in the background
                imageProcessor: processImage,

                // editor options
                editorOptions: {
                    imageOrienter: createDefaultImageOrienter(),
                    ...plugin_finetune_defaults,
                    ...plugin_filter_defaults,
                    ...markup_editor_defaults,
                    locale: {
                        ...locale_en_gb,
                        ...plugin_crop_locale_en_gb,
                        ...plugin_finetune_locale_en_gb,
                        ...plugin_filter_locale_en_gb,
                        ...plugin_annotate_locale_en_gb,
                        ...markup_editor_locale_en_gb,
                    },
                },
            }


const handleFilePondInit = () => {
    console.log('FilePond has initialized');    // FilePond instance methods are available on `this.$refs.pond`
    instantEdit.value = true
}
const handleFileAdded = (file) => {
    console.log('does this work')
    console.log('added', file)
}


const instantEdit = ref(false)
const cropperReady = ref(false)

onMounted( () => {
    cropperReady.value = true
})
// Create FilePond component


const editorProps = {
                imageReader: createDefaultImageReader(),
                imageWriter: createDefaultImageWriter(),
                ...plugin_finetune_defaults,
                ...plugin_filter_defaults,
                ...markup_editor_defaults,
                locale: {
                    ...locale_en_gb,
                    ...plugin_crop_locale_en_gb,
                    ...plugin_finetune_locale_en_gb,
                    ...plugin_filter_locale_en_gb,
                    ...plugin_annotate_locale_en_gb,
                    ...markup_editor_locale_en_gb,
                },
            }

</script>
<script>

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEditor from 'filepond-plugin-image-editor';
import 'pintura/pintura.css';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import 'filepond/dist/filepond.min.css';
// vue-pintura
import { PinturaEditor, PinturaEditorModal, PinturaEditorOverlay } from 'vue-pintura';

// pintura
import {
    // editor
    createDefaultImageReader,
    createDefaultImageWriter,
    locale_en_gb,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    plugin_filter,
    plugin_filter_defaults,
    plugin_filter_locale_en_gb,
    plugin_finetune,
    plugin_finetune_defaults,
    plugin_finetune_locale_en_gb,
    plugin_annotate,
    plugin_annotate_locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,

    // filepond
    openEditor,
    processImage,
    createDefaultImageOrienter,
    legacyDataToImageState,
} from 'pintura';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImageEditor,
    FilePondPluginFileEncode
    //FilePondPluginFilePoster
);

import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { usePage } from '@inertiajs/inertia-vue3';

export default defineComponent({
    components: {
        PinturaEditor,
        PinturaEditorModal,
        PinturaEditorOverlay,
        FilePond
    }
})
</script>

