<template>
    <vue-final-modal v-model="showModal" @closed="closed" @opened="opened" name="ewTourModal" classes="modal-container" transition="modal-open">

        <div class="modal__content" v-if="showModal">
            <div class="tour-modal-container">

                <Button class="p-button-icon-only p-button-danger close-button" @click="hide">
                    <span class="iconify p-button-icon" data-icon="ph:x-bold"></span>
                </Button>

                <div class="tour-modal-content">

                    <div class="p-fluid">
                        <AutoComplete
                            v-model="ewTourService.query"
                            placeholder="Start typing to search..."
                            class="ac-search"
                            :suggestions="ewTourService.suggestions"
                            @complete="ewTourService.searchTours($event)"
                            @item-select="ewTourService.tourSelected"
                            field="label"
                        >
                            <template #item="{ item }">
                                <div class="autocomplete-preview">
                                    <div class="autocomplete-thumbnail">
                                        <img :src="item.images.thumbnail" />
                                    </div>
                                    <div class="autocomplete-content">
                                        <h3 v-html="item.title"></h3>
                                        <h4>{{ item.city }}, {{ item.state }}</h4>
                                    </div>
                                </div>
                            </template>
                        </AutoComplete>
                    </div>

                </div>
            </div>

        </div>

    </vue-final-modal>

</template>
<script setup>
    import { inject, reactive, ref } from "vue";
    import qs from 'qs'

    const emit = defineEmits(['item-selected'])

    const showModal = ref(false)
    const axios = inject('axios')

    const ewPrefix = 'https://api.everwondr.com'

    const targetNode = ref(false)

    const opened = () => {

    }
    const closed = () => {

    }

    const show = (target) => {
        showModal.value = true
        targetNode.value = !!target ? target : false
    }

    const hide = () => {
        showModal.value = false
        targetNode.value = false
        ewTourService.query = ''
        ewTourService.suggestions = []
    }

    const ewTourService = reactive({
        query: '',
        suggestions: [],
        searchTours: async ({ query }) => {

            axios
                .get(`/autocomplete/tours/${query}`,
                    {
                        //params: {
                        //    data: { search_term: query, callback: null }
                        //},
                        //paramsSerializer: (params) => {
                        //    return qs.stringify(params, { arrayFormat: 'repeat' })
                        //},
                    }
                )
                .then( (response) => {

                    ewTourService.suggestions = response.data.results;

                } )

        },
        tourSelected: (tour) => {

            emit('item-selected', tour.value, targetNode.value)
            hide()

        },
        getThumbnail: (thumbnail) => {


            return ewPrefix+thumbnail

        }
    })

    defineExpose({
        show,
        hide
    })
</script>

<style lang="scss" scoped>
    ::v-deep(.modal-container) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ::v-deep(.modal-content) {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 90%;
        margin: 0 1rem;
        padding: 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        background: #fff;
    }
    .modal__title {
        margin: 0 2rem 0 0;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .modal__content {
        flex-grow: 1;
    }
    .modal__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem 0 0;
    }
    .modal__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
    .tour-modal-container {
        border-radius: 6px 0 6px 6px;
        background: #ffffff;
        box-shadow: 0 0 4px rgba(0,0,0,.07), 1px 1px 10px rgba(0,0,0,.1);
        width: 380px;
        max-width: 100%;
        position: relative;
        .tour-modal-content {
            padding: 1.4rem;
        }
        .close-button {
            position: absolute;
            transform: translateY(-100%);
            right: 0;
            border-radius: 6px 6px 0 0;
            padding: 0;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .autocomplete-preview {
        display: flex;
        align-items: center;
        max-width: 320px;
        white-space: normal;
        .autocomplete-thumbnail {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            border: 3px solid rgba(0,0,0,.1);
            margin-right: 10px;
            img {
                display: block;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .autocomplete-content {
            h3 {
                font-size: .85rem;
                margin: 0 0 2px 0;
                font-weight: 600;
            }
            h4 {
                font-size: .7rem;
                font-weight: 500;
                color: #888;
                margin: 0 0 5px 0;
                font-style: italic;
            }
            p {
                margin: 0;
                font-size: .875rem;
            }
        }
    }
</style>
