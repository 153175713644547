export default [
    {
        type: 'post',
        label: 'Blog',
        fields: {
            title: '',
            image: null,
            url: '',
            object: null
        }
    },
    {
        type: 'event',
        label: 'Event',
        fields: {
            title: '',
            image: null,
            url: '',
            object: null
        }
    },
    {
        type: 'venue',
        label: 'Directory',
        fields: {
            title: '',
            image: null,
            url: '',
            object: null
        }
    },
    {
        type: 'video',
        label: 'Video',
        fields: {
            title: '',
            image: null,
            video: {}
        }
    },
    {
        type: 'pdf',
        label: 'PDF',
        fields: {
            title: '',
            image: null,
            file: ''
        }
    },
    {
        type: 'custom',
        label: 'Custom',
        fields: {
            title: '',
            image: null,
            url: ''
        }
    },
]
