<template>
  <vue-final-modal v-model="visible" @closed="closed" v-bind="$attrs" classes="modal-container" content-class="modal-content" transition="modal-open">

    <span class="modal__title">
        Choose a Grid Layout
    </span>
    <div class="modal__content">

        <div class="grid-options">


            <a @click="selected = option" v-for="(option, index) in options" :key="index" class="grid-option" :style="{'grid-template-columns': option.span }" :class="{'selected': selected == option}">
                <div class="column" v-for="(column, cdx) in option.columns" :key="'column-'+cdx"></div>
            </a>

        </div>

        <div class="style-options">

            <div class="style-option">
                <h3>Max Width:</h3>
                <InputNumber class="p-inputnumber-sm" suffix="px" v-model="styleDefaults.maxWidth" :max="700" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
            </div>
            <div class="style-option">
                <h3>Column Gap:</h3>
                <InputNumber class="p-inputnumber-sm" suffix="px" v-model="styleDefaults.gap" :max="40" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
            </div>

        </div>

    </div>

    <div class="modal__action">
        <Button @click="insert" class="p-button-sm p-button-rounded p-button-success">
            <span class="p-button-label">Insert</span>
        </Button>
        <Button @click="hide" class="p-button-sm p-button-text p-button-rounded p-button-danger p-cancel-button">
            <span class="iconify p-button-icon" data-icon="ph:x-circle-fill"></span>
            <span class="p-button-label">Cancel</span>
        </Button>
    </div>

    <Button class="modal__close p-button-icon-only p-button-rounded p-button-danger" @click="hide">
      <span class="iconify" data-icon="ph:x-bold"></span>
    </Button>
  </vue-final-modal>
</template>

<script setup>

import { ref, reactive } from "vue"
import { cloneDeep } from 'lodash'

const emit = defineEmits(['insert'])


const selected = ref(null)


const insert = () => {
    let val = JSON.parse(JSON.stringify(selected.value))
    let st = JSON.parse(JSON.stringify(styleDefaults.value))
    emit('insert', cloneDeep({ type: 'grid', ...selected.value, ...styleDefaults.value }))
    hide()
}

const styleDefaults = ref({
    gap: 25,
    maxWidth: 700,
    margin: {
        top: '1.5rem',
        left: 'auto',
        right: 'auto',
        bottom: '1.5rem'
    },
})


const options = ref([

    {
        span: '2fr 1fr',
        columns: [
            {
                blocks: []
            },
            {
                blocks: []
            }
        ]
    },
    {
        span: '1fr 2fr',
        columns: [
            {
                blocks: []
            },
            {
                blocks: []
            }
        ]
    },
    {
        span: '1fr 1fr 1fr',
        columns: [
            {
                blocks: []
            },
            {
                blocks: []
            },
            {
                blocks: []
            }
        ]
    },
    {
        span: '1fr 1fr',
        columns: [
            {
                blocks: []
            },
            {
                blocks: []
            }
        ]
    },
    {
        span: '1fr',
        columns: [
            {
                blocks: []
            },
        ]
    },


])


const visible = ref(false)

const show = () => {
    visible.value = true
}
const hide = () => {
    visible.value = false
}

const closed = () => {
    selected.value = null
}

defineExpose({
    hide,
    show
})

</script>
<style lang="postcss" scoped>
::v-deep(.p-inputnumber) {
    width: 80px;
    input {
        min-width: 0;
    }
    .p-button.p-button-icon-only {
        width: 2rem;
    }

}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;

}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 0 1rem;

  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  min-width: 400px;
  max-width: 600px;
}

.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1.1rem 1rem 0;
}
.modal__close.p-button.p-button-icon-only.p-button-rounded {
    width: 2rem;
    height: 2rem;
}
.modal__content {
  flex-grow: 1;

  padding: 1.4rem 1rem;
}
.modal__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: .875rem;
  border-top: 2px solid #eeeeee;
  background: #f8f8f8;
}
.modal__action .p-button {
    display: flex;
    align-items: center;
}
.modal__action .p-button .p-button-icon {
    font-size: 1rem;
    margin-right: 3px;
}
.p-cancel-button {
    margin-left: .5rem;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}


.grid-options {
    display: flex;
    gap: 1rem;
    height: auto;
    justify-content: center;
    .grid-option {
        width: 50px;
        height: 30px;
        min-width: 0;
        display: grid;
        gap: 2px;
        padding: 3px;
        background: #fafafa;
        border-radius: 3px;
        box-shadow: 0 0 0 2px #dedede;
        transition: box-shadow .3s ease;
        &.selected {
            box-shadow: 0 0 0 2px #75bef8;
            .column {
                background: #75bef8;
            }
        }
        .column {
            border-radius: 2px;
            background: #d1d1d1;
            height: 100%;
            transition: background .3s ease;
            min-width: 0;

        }
    }
}

.style-options {
    display: flex;
    margin: 1.4rem auto 0;
    justify-content: center;
    gap: 1rem;
    max-width: 400px;
    .style-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
            font-size: .8rem;
            margin: 0 0 6px 0;
        }
        ::v-deep(.p-inputtext) {
            width: 80px;
            padding: .35rem;
        }
    }
}
</style>


