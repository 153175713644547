<template>

        <div class="related-section">

            <a class="remove-section" @click="emit('removeSection')">
                <span class="iconify" data-icon="ph:x-bold"></span>
            </a>

            <a class="toggle-section" :class="{'collapsed': collapsed}" @click="toggleSection">
                <!--<span class="iconify" data-icon="ph:caret-down-fill"></span>-->
                <span class="toggle-icon">{{ collapsed ? '+' : '-' }}</span>
                <span class="toggle-text" v-html="collapsed ? 'EXPAND' : 'COLLAPSE'"></span>
            </a>

            <div class="p-fluid p-formgrid p-grid" v-if="!collapsed">
                <TextField class="p-field p-col" label="Section Title" v-model="section.title" name="title" :validator="$v.title"  placeholder="Enter a title">
                </TextField>
            </div>

            <h3 class="collapsed-title" v-html="section.title" v-if="collapsed"></h3>

            <slot name="sort"></slot>

            <div class="item-count" v-if="collapsed">
                <a class="item-count-badge" @click="toggleSection">
                    <span v-html="section.items.length"></span> items
                </a>
            </div>

            <div class="collapsible-content" v-if="!collapsed">

            <draggable
                :list="section.items"
                group="list"
                class="item-blocks"
                handle=".drag-handle"
                @start="enableDrag"
                @end="disableDrag"
                item-key="uuid">
                <template #item="{element, index}">


                    <related-content-item @removeBlock="removeBlock(index)" v-model="section.items[index]" :index="index" :uuid="element.uuid">
                        <template #sort>
                            <a class="handle drag-handle">
                                <span class="iconify" data-icon="ph:dots-six-bold"></span>
                            </a>
                        </template>
                    </related-content-item>
                </template>

            </draggable>

            <!--<related-content-item @removeBlock="removeBlock(bdx)" v-model="section.items[bdx]" v-for="(block, bdx) in section.items" :key="bdx"></related-content-item>-->



            <div class="block-add-wrapper">
                <Dropdown :options="blockTypes" placeholder="Add a Block" @change="addBlock($event)">
                    <template #option="slotProps">
                        Add {{ slotProps.option.label }}
                    </template>
                </Dropdown>
            </div>

            </div>

            <teleport to="body">
                <ew-post-modal ref="postModal" @item-selected="callbacks.postSelected"></ew-post-modal>
                <ew-event-modal ref="eventModal" @item-selected="callbacks.eventSelected"></ew-event-modal>
                <ew-venue-modal ref="venueModal" @item-selected="callbacks.venueSelected"></ew-venue-modal>
                <parse-video-modal ref="videoModal" @video-selected="callbacks.videoSelected"></parse-video-modal>
            </teleport>

        </div>





</template>
<script setup>
    import { ref, computed, inject, provide } from 'vue'
    import blockTypes from './blockTypes.js'
    import { format, parseJSON, add } from 'date-fns'
    import { helpers, minLength, required } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'

    import RelatedContentItem from './RelatedContentItem.vue'

    const chance = inject('chance')

    const emit = defineEmits(['update:modelValue', 'removeSection'])

    const ewPrefix = 'https://api.everwondr.com'

    const props = defineProps({
        modelValue: {
            type: Object,
            default: {}
        }
    })

    const collapsed = ref(false)

    const toggleSection = () => {
        collapsed.value = !collapsed.value
    }

    const draggingBlock = inject('draggingBlock')

    const enableDrag = () => {
        draggingBlock.value = true
    }
    const disableDrag = () => {
        setTimeout( () => {
            draggingBlock.value = false
        }, 400)
    }

    const uploaders = ref({

    })

    const setUploaderRef = (el, bdx) => {
        uploaders.value['el'+bdx] = el
    }

    const blockOptions = ref({
        ...blockTypes
    })

    const section = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const postModal = ref()
    const eventModal = ref()
    const venueModal = ref()
    const videoModal = ref()

    const callbacks = {

        postSelected: (post) => {
            //target.title = post.title
            //target.object = post

            let obj = {
                type: 'post',
                url: '',
                title: post.title,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                object: {
                    url_title: post.url_title,
                    street_address: post.address_1,
                    city: post.city,
                    state: post.state,
                    zip: post.zip,
                    lat: post.lat,
                    lng: post.lng,
                    summary: post.summary,
                    first_name: post.first_name,
                    last_name: post.last_name
                }
            }

            let photos = post.uploads.filter( (upload) => {
                return upload.type == 'photo'
            })
            if ( photos.length ) {
                obj.image = ewPrefix+photos[0].source
            }

            section.value.items.push({...obj})

        },
        eventSelected: (event) => {
            let obj = {
                type: 'event',
                url: '',
                title: event.name,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                object: {
                    url_title: event.url_title,
                    street_address: event.address_1,
                    city: event.city,
                    state: event.state,
                    zip: event.zip,
                    lat: event.lat,
                    lng: event.lng,
                    next_date: event.next_date,
                    all_dates: event.all_dates,
                    summary: event.trunc_description
                }
            }
            if ( !!event.thumbnail ) {
                obj.image = ewPrefix+event.thumbnail
            }

            section.value.items.push({...obj})

        },
        venueSelected: (venue) => {
            let obj = {
                type: 'venue',
                url: '',
                title: venue.name,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                object: {
                    url_title: venue.url_title,
                    street_address: venue.address_1,
                    city: venue.city,
                    state: venue.state,
                    zip: venue.zip,
                    lat: venue.lat,
                    lng: venue.lng,
                    phone: venue.phone,
                    email: venue.email,
                    types: venue.venue_types
                }
            }
            if ( !!venue.thumbnail ) {
                obj.image = ewPrefix+venue.thumbnail
            }

            section.value.items.push({...obj})

        },
        videoSelected: (video) => {
            console.log('VIDEO', video.value)

            let obj = {
                type: 'video',
                url: '',
                title: video.value.title,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                image: video.value.thumbnail,
                id: video.value.id,
                provider: video.value.provider,
                object: {
                    id: video.value.id,
                    provider: video.value.provider
                }
            }


            console.log('VIDEO PARSED', video)

            section.value.items.push({...obj})

        }

    }

    const addBlock = (event) => {

        const val = event.value;

        if ( ['custom', 'pdf'].includes(val.type) ) {
            section.value.items.push({
                type: val.type,
                label: val.label,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                ...val.fields
            })
        }
        if ( val.type == 'post' ) {
            postModal.value.show(  )
        }
        if ( val.type == 'event' ) {
            eventModal.value.show(  )
        }
        if ( val.type == 'venue' ) {
            venueModal.value.show(  )
        }
        if ( val.type == 'video' ) {
            videoModal.value.show(  )
        }
    }

    const removeBlock = (index) => {

        section.value.items.splice(index, 1)

    }


    const rules =
        computed( () =>
        ({
            title: { required: helpers.withMessage('A section title is required', required) },
            items: { required: helpers.withMessage('A section must contain at least one item', required), minLength: helpers.withMessage('A section must contain at least one item', minLength(1))  }
        }))

    const $v = useVuelidate(rules, section, { $registerAs: 'related_section'})


</script>

<style lang="scss">

.collapsed-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: .3rem 0 .8rem;
}

    .toggle-section {

        margin-bottom: 5px;
        display: flex;
        position: absolute;
        left: 3px;
        top: 3px;
        height: 1.75rem;
        padding: 0 10px;
        background-color: #f6f6f6;
        transition: background-color .3s ease;
        box-shadow: 1px 1px rgba(0,0,0,.08);
        display: flex;
        color: #444;
        align-items: center;
        border-radius: 5px;
        line-height: 1.75rem;
        &:hover {
            background-color: #efefef;
        }
        &.collapsed {
            svg {
                transform: rotate(180deg);
            }
        }
        .toggle-icon {
            font-weight: 800;
            font-size: 1rem;
        }
        .toggle-text {
            font-size: .75rem;
            font-weight: 600;
            margin-left: 5px;
            color: #666;
        }

    }

    .item-count {
        display: flex;
        margin: 1rem 0;
        justify-content: center;
        align-items: center;
        &:after, &:before {
            flex-grow: 1;
            height: 3px;
            background: #f6f6f6;
            content: '';
        }
        .item-count-badge {
            margin: 0 .7rem;
            font-size: .8rem;
            font-weight: 700;
            text-transform: uppercase;
            color: #83888F;
            background-color: #f6f6f6;
            transition: background-color .3s ease;
            box-shadow: 1px 1px rgba(0,0,0,.05);
            padding: 0 25px;
            height: 2rem;
            line-height: 2rem;
            border-radius: 1rem;
            &:hover {
                background-color: #efefef;
            }
        }
    }

    .related-section {
        padding-bottom: 2.4rem;
        .drag-handle {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            left: 0;
            bottom: 0;
            color: #666;
            background: #f8f8f8;
            border-top: 1px solid #eee;
            border-radius: 0 0 5px 5px;

            height: 18px;
            cursor: grab;
            &:hover {
                background: #f3f3f3;
                color: #444;
            }
        }
    }
</style>
