<template>
    <vue-final-modal v-model="showModal" @closed="closed" @opened="opened" name="ewRelatedModal" classes="modal-container" transition="modal-open">

        <div class="modal__content" v-if="showModal">
            <div class="related-modal-container">

                <Button class="p-button-icon-only p-button-danger close-button" @click="hide">
                    <span class="iconify p-button-icon" data-icon="ph:x-bold"></span>
                </Button>

                <div class="related-modal-title">
                    <h3>Add from Existing</h3>
                </div>

                <div class="related-modal-content">

                    <SelectButton class="mini-select" v-model="selectedType" optionValue="model" :options="relatedTypes" optionLabel="label" />

                    <div class="p-fluid related-autocomplete" >
                        <AutoComplete
                            v-model="ewRelatedService.query"
                            placeholder="Start typing to search..."
                            class="ac-search"
                            :disabled="selectedParent"
                            :field="($event) => getTitle($event)"
                            :suggestions="ewRelatedService.suggestions"
                            @complete="ewRelatedService.searchRelated($event)"
                            @item-select="ewRelatedService.relatedSelected"
                        >
                            <template #item="{ item }">
                                <div class="autocomplete-preview">

                                    <span class="item-name" v-html="getTitle(item)"></span>
                                </div>
                            </template>
                        </AutoComplete>
                        <a class="close-selected" @click="deselectParent" v-if="selectedParent">
                            <span class="iconify" data-icon="ph:x-bold"></span>
                        </a>
                    </div>

                    <div class="" v-if="selectedParent">


                        <div class="related-option-wrapper" v-for="(section, index) in selectedParent.related_items" :key="index" :class="{ 'is-selected': isSelected(section) }">
                            <div class="p-field-checkbox section-option-checkbox">
                                <Checkbox :id="'related'+index" name="related" :value="section" v-model="selectedRelated"  />
                                <label :for="'related'+index">
                                    <span class="section-option-title" v-html="section.title"></span>
                                    <span class="section-option-count">({{ section.items.length }} items)</span>
                                </label>
                            </div>
                            <div class="related-option-items">
                                <div class="related-option-item" v-for="(item, idx) in section.items" :key="'item-'+idx">
                                    <img :src="item.image" />
                                    <h5 v-html="item.type"></h5>
                                    <h4 v-html="item.title"></h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div  class="related-controls">
                    <Button :disabled="!selectedRelated.length" class="p-button-sm p-button-success flex items-center" @click="save">
                        <span style="width: 1.2rem; height: 1.2rem; margin-right: 4px;" class="iconify p-button-icon" data-icon="ph:floppy-disk-bold"></span>
                        <span class="p-button-text">Add Items</span>
                    </Button>
                </div>

            </div>



        </div>

    </vue-final-modal>

</template>
<script setup>
    import { inject, reactive, ref } from "vue";
    import qs from 'qs'

    const emit = defineEmits(['selected'])

    const showModal = ref(false)
    const axios = inject('axios')

    const ewPrefix = 'https://api.everwondr.com'

    const targetNode = ref(false)

    const selectedParent = ref(null)
    const selectedRelated = ref([])

    const isSelected = (item) => {
        return selectedRelated.value.includes(item)
    }

    const deselectParent = () => {
        selectedParent.value = null
        selectedRelated.value = []
        ewRelatedService.query = ''
        ewRelatedService.suggestions = []
    }

    const relatedTypes = [
        { model: 'Story', label: 'Stories' },
        { model: 'TodoList', label: 'Things to Do' },
        { model: 'Tour', label: 'Tours' },
        { model: 'Visit', label: 'Visit' }
    ]

    const selectedType = ref('Story');

    const opened = () => {

    }
    const closed = () => {

    }
    const save = () => {
        emit('selected', [ ...selectedRelated.value ])
        hide()
    }

    const show = (target) => {
        showModal.value = true
        targetNode.value = !!target ? target : false
    }

    const hide = () => {
        showModal.value = false
        targetNode.value = false
        selectedParent.value = null
        selectedRelated.value = []
        ewRelatedService.query = ''
        ewRelatedService.suggestions = []
    }

    const ewRelatedService = reactive({
        query: '',
        suggestions: [],
        searchRelated: async ({ query }) => {

            axios({
                method: 'post',
                url: '/admin/search/all',
                data: {
                    keyword: query,
                    model: selectedType.value
                }
            }).then(function (response) {
                console.log("RESPONSE", response)
                ewRelatedService.suggestions = response.data.results;
            });

        },
        relatedSelected: (event) => {

            //emit('item-selected', event.value, targetNode.value)
            selectedParent.value = event.value


        }
    })

    const getTitle = (item) => {

        return item.name ? item.name
            : item.title ? item.title
            : item.headline;

        if ( ['Story', 'Tour', 'Spotlight'].includes(selectedType.value) ) {
            return item.title;
        } else if ( selectedType.value == 'Visit' ) {
            return item.headline;
        } else if ( selectedType.value == 'TodoList' ) {
            return item.name;
        }
    }

    defineExpose({
        show,
        hide
    })
</script>

<style lang="scss" scoped>
    ::v-deep(.mini-select) {
        margin-bottom: 15px;
        .p-button {
            padding: .4rem .8rem;
            font-size: .85rem;
        }
    }
    .related-autocomplete {
        position: relative;
        width: 100%;
        margin-bottom: 1rem;
        .close-selected {
            height: 40px;
            width: 40px;
            position: absolute;
            display: flex;
            color: #D32F2F;
            align-items: center;
            justify-content: center;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
    .selected-title-wrapper {
        display: flex;
        margin-bottom: .9rem;
        justify-content: space-between;
        h3 {
            margin: 0 25px 0 0;
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
        .close-selected {
            display: flex;
            color: #D32F2F;
            align-items: center;
            line-height: 1rem;
            height: 1.2rem;
            .text {
                margin-left: 5px;
                font-size: .7rem;
                font-weight: 700;
                opacity: .75;
            }
            &:hover {
                .text {
                    opacity: 1;
                }
            }
        }
    }
    .related-option-items {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        min-width: 0;
        gap: 8px;
        margin-top: .7rem;
        display: none;
        .related-option-item {
            position: relative;
            img {
                width: 100%;
                height: 50px;
                object-fit: cover;
                display: block;
                border-radius: 3px;
            }
            h5 {
                background: rgba(255,255,255,1);
                padding: 3px 5px;
                border-radius: 2px;
                box-shadow: 1px 1px 1px rgba(0,0,0,.1);
                font-size: .6rem;
                font-weight: 700;
                position: absolute;
                text-transform: uppercase;
                top: 3px;
                right: 3px;
                margin: 0;
            }
            h4 {
                font-size: .75rem;
                margin: 5px 2px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .related-option-wrapper {
        margin-bottom: .8rem;
        &.is-selected {

            .related-option-items {
                display: grid;
            }
        }
        .section-option-checkbox {
            margin-bottom: 0;
            label {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: .95rem;
                .section-option-title {
                    font-weight: 600;
                    margin-right: 5px;
                }
                .section-option-count {
                    font-size: .85rem;
                    opacity: .8;
                }
            }
        }
    }
    ::v-deep(.modal-container) {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 100vh;

    }
    ::v-deep(.modal-content) {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 90%;
        margin: 0 1rem;
        padding: 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        background: #fff;


    }
    .modal__title {
        margin: 0 2rem 0 0;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .modal__content {
        flex-grow: 1;
        max-height: 100vh;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow-y: auto;
    }
    .modal__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem 0 0;
    }
    .modal__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
    .related-modal-container {
        border-radius: 6px;
        background: #ffffff;
        box-shadow: 0 0 4px rgba(0,0,0,.07), 1px 1px 10px rgba(0,0,0,.1);
        width: 440px;
        max-width: 100%;
        position: relative;
        .related-modal-content {
            padding: 1.4rem;
        }
        .related-modal-title {
            padding: 0rem 1.4rem;
            height: 46px;
            display: flex;
            align-items: center;
            background: #fafafa;
            border-radius: 6px 6px 0 0;
            border-bottom: 1px solid #f1f1f1;
        }
        .related-modal-title h3 {
            margin: 0;
            font-size: .925rem;
        }
        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 4px;
            padding: 0;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .related-controls {
        padding: .5rem 1.4rem 1.4rem;
    }
    .autocomplete-preview {
        display: flex;
        align-items: center;
        max-width: 320px;
        white-space: normal;
        .autocomplete-thumbnail {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            border: 3px solid rgba(0,0,0,.1);
            margin-right: 10px;
            img {
                display: block;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .autocomplete-content {
            h3 {
                font-size: .85rem;
                margin: 0 0 2px 0;
                font-weight: 600;
            }
            h4 {
                font-size: .7rem;
                font-weight: 500;
                color: #888;
                margin: 0 0 5px 0;
                font-style: italic;
            }
            p {
                margin: 0;
                font-size: .875rem;
            }
        }
    }
</style>
