/*
|--------------------------------------------------------------------------
| Main entry point
|--------------------------------------------------------------------------
| Files in the "resources/scripts" directory are considered entrypoints
| by default.
|
| -> https://vitejs.dev
| -> https://github.com/innocenzi/laravel-vite
*/

import { computed, createApp, h, nextTick, provide, resolveComponent, watch, ref } from 'vue'
import { createInertiaApp, usePage } from '@inertiajs/inertia-vue3';

import PrimeVue from 'primevue/config';
// Also import the toast's css
import "vue-toastification/dist/index.css";

import AutoComplete from 'primevue/autocomplete';
import Ripple from 'primevue/ripple';
import SpeedDial from 'primevue/speeddial';
import ContextMenu from 'primevue/contextmenu';
import TieredMenu from 'primevue/tieredmenu';
import Tooltip from 'primevue/tooltip';
import "@/scss/main.scss";
//import 'prismjs/themes/prism-coy.css';
import '@iconify/iconify'
import '@purge-icons/generated'


import TextField from './Backend/Components/Fields/TextField.vue'
import TextareaField from './Backend/Components/Fields/TextareaField.vue'
import MiscField from './Backend/Components/Fields/MiscField.vue'
import SwitchField from './Backend/Components/Fields/SwitchField.vue'
import AccountGuideSelector from './Backend/Components/Fields/AccountGuideSelector.vue';
import ErrorMessage from './Backend/Layouts/Partials/ErrorMessage.vue'

import ContentEditable from './Backend/Components/ContentEditable.vue'

import Chance from 'chance'

import CKEditor from '@ckeditor/ckeditor5-vue';

import axios from 'axios'
import { Head, Link } from '@inertiajs/inertia-vue3'
const el = document.getElementById("app");

import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import Toast, { PluginOptions, useToast } from "vue-toastification";
//import { QuillEditor } from '@vueup/vue-quill'
import Cropper from './Backend/Components/Cropper.vue'
import UploadCrop from './Backend/Components/UploadCrop.vue'
import BgUploader from './Backend/Components/BgUploader.vue'
import BlockEditor from './Backend/Components/BlockEditor/BlockEditor.vue'
import StandardEditor from './Backend/Components/BlockEditor/Blocks/Standard.vue'
import AirEditor from './Backend/Components/BlockEditor/Blocks/AirEditor.vue'

import EwPostModal from './Backend/Components/Modals/EwPostModal.vue'
import EwEventModal from './Backend/Components/Modals/EwEventModal.vue'
import EwVenueModal from './Backend/Components/Modals/EwVenueModal.vue'
import EwTourModal from './Backend/Components/Modals/EwTourModal.vue'
import ParseVideoModal from './Backend/Components/Modals/ParseVideoModal.vue'
import StatusModal from './Backend/Components/Modals/StatusModal.vue'

import Breadcrumbs from "./Backend/Layouts/Partials/Breadcrumbs.vue"
import RowActions from "./Backend/Layouts/Partials/RowActions.vue"
import MultiActions from "./Backend/Layouts/Partials/MultiActions.vue"
import Menu from "./Backend/Layouts/Partials/Menu/Menu.vue"
import draggable from 'vuedraggable'

import SpotlightLink from './Backend/Pages/Spotlight/Partials/SpotlightLink.vue'

import { plugin as VueTippy } from 'vue-tippy'


const pages = import.meta.glob('./Backend/Pages/**/*.vue');

import { useVuelidate } from '@vuelidate/core'
import { ValidateEach } from '@vuelidate/components'
import GooglePlaces from './Backend/Components/Fields/GooglePlaces.vue'

import RelatedContent from './Backend/Components/Fields/RelatedContent.vue'

import defaultLayout from './Backend/Layouts/Dashboard.vue'
import ConfirmModal from "./Backend/Layouts/Partials/ConfirmModal.vue"

import { InertiaProgress } from '@inertiajs/progress'

InertiaProgress.init({
    // The delay after which the progress bar will
    // appear during navigation, in milliseconds.
    delay: 250,

    // The color of the progress bar.
    color: '#29d',

    // Whether to include the default NProgress styles.
    includeCSS: true,

    // Whether the NProgress spinner will be shown.
    showSpinner: false,
  })

createInertiaApp({
    id: 'app',
    resolve: async (name) => {
        const importPage = pages[`./Backend/Pages/${name}.vue`];

        if (!importPage) {
            throw new Error(`Unknown page ${name}. Is it located under Pages with a .vue extension?`);
        }

        return importPage().then((module) => {
            let page = module.default
            page.layout = page.layout || defaultLayout
            return page
        });
    },
    setup({ el, app: App, props, plugin }) {
      const wndr = createApp({
          render: () => h(App, props),
          setup() {

            const layoutLoaded = ref(false)

            provide('layoutLoaded', layoutLoaded)

            provide('axios', axios)
            provide('useVuelidate', useVuelidate)

            const toast = useToast();
            provide('toast', toast)
            const flash = computed( () => usePage().props.value.flash  );

            const chance = new Chance();
            provide('chance', chance)

            nextTick( () => {
                watch(
                    () => flash.value,
                    (newVal, oldVal) => {
                        if ( newVal.error ) {
                            toast.error( newVal.error )
                        }
                        if ( !!newVal.success ) {
                            toast.success( newVal.success )
                        }
                    },
                    { immediate: true }
                )
            })

          }
        })
        .component('Link', Link)
        .component('Head', Head)
        .component('Cropper', Cropper)
        //.component('QuillEditor', QuillEditor)
        .component('Breadcrumbs', Breadcrumbs)
        //.component('InlineMessage', InlineMessage)
        .component('SwitchField', SwitchField)
        .component('ValidateEach', ValidateEach)
        .component('TextField', TextField)
        .component('TextareaField', TextareaField)
        .component('MiscField', MiscField)
        .component('GooglePlaces', GooglePlaces)
        .component('UploadCrop', UploadCrop)
        .component('BgUploader', BgUploader)
        .component('SpeedDial', SpeedDial)
        .component('ContextMenu', ContextMenu)
        .component('Menu', Menu)
        .component('RowActions', RowActions)
        .component('MultiActions', MultiActions)
        .component('VueFinalModal', VueFinalModal)
        .component('ModalsContainer', ModalsContainer)
        .component('ConfirmModal', ConfirmModal)
        .component('EwPostModal', EwPostModal)
        .component('EwEventModal', EwEventModal)
        .component('EwVenueModal', EwVenueModal)
        .component('EwTourModal', EwTourModal)
        .component('StatusModal', StatusModal)
        .component('ParseVideoModal', ParseVideoModal)
        .component('RelatedContent', RelatedContent)
        .component('router-link', {})
        .component('BlockEditor', BlockEditor)
        .component('StandardEditor', StandardEditor)
        .component('AirEditor', AirEditor)
        .component('AccountGuideSelector', AccountGuideSelector)
        .component('draggable', draggable)
        .component('ContentEditable', ContentEditable)
        .component('SpotlightLink', SpotlightLink)
        .use(
            VueTippy,
            // optional
            {
              directive: 'tippy', // => v-tippy
              component: 'tippy', // => <tippy/>
              componentSingleton: 'tippy-singleton', // => <tippy-singleton/>
              defaultProps: {
                  theme: 'wg',
                  placement: 'top',
                  animation: "perspective",
                  offset: [0, 4],
                  allowHtml: true
              }
            }
        )
        .directive('tooltip', Tooltip)
        .use(CKEditor)
        .use(plugin)
        .use(PrimeVue, {
            zIndex: {
                //modal: 1100,        //dialog, sidebar
                overlay: 3000,      //dropdown, overlaypanel
            }
        });

        const options: PluginOptions = {
            // You can set your default options here
        };

        wndr.use(Toast, options);

        wndr.provide('$page', computed(() => wndr.config.globalProperties.$page));

        wndr.mount('#app');
    },
})


