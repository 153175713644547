<template>
    <vue-final-modal v-model="showModal" @closed="closed" @opened="opened" name="statusModal" classes="modal-container" transition="modal-open">

        <div class="modal__content" v-if="showModal">


            <div class="status-modal-container">

                <div class="status-modal-title">
                        <h3>Set Status</h3>
                </div>

                <div class="status-modal-content">
                    <SelectButton v-model="selectedStatus" :options="statusOptions" optionLabel="label" optionValue="value" />
                </div>

                <div class="status-controls">
                    <Button class="p-button-sm p-button-danger flex items-center" @click="hide()">
                        <span class="p-button-text">Cancel</span>
                    </Button>

                    <Button class="p-button-sm p-button-success flex items-center" @click="setStatus()">
                        <span class="p-button-text">Set Status</span>
                    </Button>
                </div>
            </div>
        </div>

    </vue-final-modal>

</template>
<script setup>
    import { inject, reactive, ref } from "vue";

    const axios = inject('axios')
    const toast = inject('toast')

    const props = defineProps({
        table: {
            type: String
        },
        selection: {
            default: []
        }
    })

    const emit = defineEmits(['status-updated'])

    const showModal = ref(false)

    const selectedStatus = ref('draft');

    const statusOptions = [
        { value: 'draft', label: 'Draft' },
        { value: 'published', label: 'Published' }
    ]

    const opened = () => {

    }
    const closed = () => {

    }

    const show = () => {
        showModal.value = true
    }

    const hide = () => {
        showModal.value = false
    }


    const setStatus = () => {
        console.log('SELECTIONS', props.selection)
        console.log('STATUS', selectedStatus.value)

        axios.post(`/admin/${props.table}/set_status`, { items: [ ...props.selection ], status: selectedStatus.value })
        .then((response) => {
            toast.info(`Status was successfully set to ${selectedStatus.value}`)
            emit('status-updated')
            hide();
        });
    }


    defineExpose({
        show,
        hide
    })
</script>

<style lang="scss" scoped>
    ::v-deep(.modal-container) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ::v-deep(.modal-content) {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 90%;
        margin: 0 1rem;
        padding: 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        background: #fff;
    }
    .modal__title {
        margin: 0 2rem 0 0;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .modal__content {
        flex-grow: 1;
    }
    .modal__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem 0 0;
    }
    .modal__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
    .status-modal-container {
        border-radius: 6px 0 6px 6px;
        background: #ffffff;
        box-shadow: 0 0 4px rgba(0,0,0,.07), 1px 1px 10px rgba(0,0,0,.1);
        width: 380px;
        max-width: 100%;
        position: relative;
        .status-modal-title {
            padding: 1rem 1rem;
            background: #f9f9f9;
            border-bottom: 1px solid #eee;
            h3 {
                margin: 0;
                height: auto;
                font-size: 1rem;
            }
        }
        .status-modal-content {
            padding: 1.4rem;
            display: flex;
            justify-content: center;
        }
        .status-controls {
            width: 100%;
            background: #f9f9f9;
            border-top: 1px solid #eee;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
        }
        .close-button {
            position: absolute;
            transform: translateY(-100%);
            right: 0;
            border-radius: 6px 6px 0 0;
            padding: 0;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
