<template>

    <div class="image-cropper-field" v-bind="$attrs" v-if="theme == 'full'">

        <div class="image-size-info">

            <span>Min. {{ minWidth }}x{{ minHeight}}. {{ extraInstructions }}</span>
        </div>

        <div class="cropper-controls-wrapper" :style="wrapperStyles" v-if="theme == 'full'">

            <div class="image-cropper-preview" v-if="outputSrc" :style="cropperDivStyles" :class="{'hovering': buttonHover}">
                <img :src="outputSrc" alt="" />

                <Button class="overlay-button" @click="changeUpload" @mouseenter="buttonHover = true" @mouseleave="buttonHover = false" :class="{'p-button-icon-only': !chooseLabel}">
                    <span class="iconify p-button-icon" data-icon="ph:upload-fill"></span>
                    <span class="p-button-text" v-if="chooseLabel">Change</span>
                </Button>
            </div>


            <div class="no-image-container" v-if="!outputSrc" :style="cropperDivStyles">
                <Button :class="{'p-button-danger': error, 'p-button-icon-only': !chooseLabel}" @click="changeUpload">
                    <span class="iconify p-button-icon" data-icon="ph:upload-fill"></span>
                    <span class="p-button-text" v-html="chooseLabel" v-if="chooseLabel"></span>
                </Button>
                <span class="form-error-text" v-html="errorMessage" v-if="error"></span>
            </div>

        </div>




    </div>


    <slot name="button" :upload="changeUpload"></slot>

    <teleport to="body">
        <div class="image-cropper-element" v-show="false">
            <!--<input type="file" ref="dropzoneEl">-->
            <FileUpload :name="name" ref="uploadField" v-model="uploadFieldModel" :choose-label="chooseLabel || 'Upload'" mode="basic" accept="image/*" :auto="true" :customUpload="true" @uploader="prepareModal" />
        </div>
        <PinturaEditorModal
            v-bind="editorProps"
            v-if="modalVisible"
            :src="modalSrc"
            @pintura:hide="handleHideModal"
            @pintura:process="handleModalProcess($event)"
            @pintura:loaderror="loadError"
        />
    </teleport>

</template>


<script setup>
import 'pintura/pintura.css';
// vue-pintura
import { PinturaEditor, PinturaEditorModal, PinturaEditorOverlay } from 'vue-pintura';
import { computed, onMounted, watch, ref, inject } from 'vue'
import { usePage } from '@inertiajs/inertia-vue3';

//import Dropzone from "dropzone";
//Dropzone.autoDiscover = false;
//import '@/scripts/Backend/Components/lib//pintura-input/pintura-input.js'
const toast = inject('toast')
// pintura
import {
    // editor
    createDefaultImageReader,
    createDefaultImageWriter,
    locale_en_gb,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    plugin_filter,
    plugin_filter_defaults,
    plugin_filter_locale_en_gb,
    plugin_finetune,
    plugin_finetune_defaults,
    plugin_finetune_locale_en_gb,
    plugin_annotate,
    plugin_annotate_locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,

    // filepond
    openEditor,
    processImage,
    createDefaultImageOrienter,
    legacyDataToImageState,
} from 'pintura';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const fileAdded = async (e, upload) => {

    let file = URL.createObjectURL(upload.file);
    let image = new Image();
    image.src = file;
    await image.decode();
    console.log( `width: ${ image.width }, height: ${ image.height }` );
}

onMounted( () => {



})


const props = defineProps({
    modelValue: {
        default: null
    },
    image: {
        type: String,
        default: null
    },
    aspectRatio: {
        default: 1
    },
    theme: {
        type: String,
        default: "full"
    },
    chooseLabel: {
        type: [String, Boolean],
        default: 'Upload Image'
    },
    name: {
        type: String,
        default: 'upload',
    },
    previewWidth: {
        type: String,
        default: 'auto'
    },
    previewPadding: {
        type: String,
        default: null
    },
    label: {
        type: String,
        default: 'Image Upload'
    },
    error: {
        default: false
    },
    errorMessage: {
        type: String,
    },
    upload: {
        default: null
    },
    uploadUrl: {
        type: [String, Boolean],
        default: null
    },
    uploadCallback: {
        type: [Boolean, Function],
        default: false
    },
    allowRemote: {
        type: Boolean,
        default: false
    },
    height: {
        default: null
    },
    width: {
        default: null
    },
    minWidth: {
        type: Number,
        default: 500
    },
    minHeight: {
        type: Number,
        default: 500
    },
    extraInstructions: {
        type: String,
        default: null
    }

})

const inputValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

const emit = defineEmits(['update:modelValue', 'addedImage'])

watch(
    () => props.image,
    (newVal, oldVal) => {

        if ( props.allowRemote && newVal !== outputSrc.value ) {

            modalSrc.value = newVal
            modalVisible.value = true
        } else if (newVal !== outputSrc.value) {
            outputSrc.value = newVal
        }
    }
)

const cropperDivStyles = computed(() => {
    let styles = {
                'max-width': props.previewWidth,
                'padding': props.previewPadding
            }
    if ( props.height ) {
        styles.height = props.height
    }
    if ( props.width ) {
        styles.width = props.width
    } else {
        styles.width = '100%'
    }
    return styles
})

const wrapperStyles = computed(() => {
    let styles = {}

    if ( props.width ) {
        styles.width = props.width
    }
    return styles
})

const loadError = () => {

    modalSrc.value = null
    modalVisible.value = false
    console.log("LETS TRY THIS", pinturaModalRef)

}

const pinturaModalRef = ref()
const modalSrc = ref(null)
const modalVisible = ref(false)

const uploadField = ref(null)
const uploadFieldModel = ref(null)

const output = ref(null)
const outputSrc = ref(null)


const buttonHover = ref(false)

if ( props.image ) {
    outputSrc.value = props.image
}

const changeUpload = () => {
    uploadField.value.choose()

}

onMounted( () => {

})

const previewSrc = computed( () => {
    return outputSrc.value || props.image
})

const handleHideModal = () => {
    modalVisible.value = false
    modalSrc.value = null
}

const prepareModal = async (event) => {

    let file = URL.createObjectURL(event.files[0]);
    let image = new Image();
    image.src = file;
    await image.decode();
    // toast.info(response.data.message)

    let imageValid = true;
    if ( image.width < props.minWidth || image.height < props.minHeight ) {
        toast.error(`Image dimensions must be exceed ${props.minWidth}x${props.minHeight}`)
        uploadField.value.clear()
        uploadField.value.uploadedFileCount = 0
        return false
    }


    modalSrc.value = event.files[0]
    modalVisible.value = true
    uploadField.value.clear()
    uploadField.value.uploadedFileCount = 0
}

const remoteUpload = (url) => {

    modalSrc.value = url
    modalVisible.value = true
    uploadField.value.clear()
    uploadField.value.uploadedFileCount = 0
}

const handleModalProcess = (event, data) => {
    if ( props.uploadUrl ) {
        //let log = JSON.parse(event.detail.store.response)
        if ( props.uploadCallback ) {
            props.uploadCallback( event.detail.store.data.path )
        } else {
            outputSrc.value = event.detail.store.data.path
            inputValue.value = event.detail.store.data.path
        }
        modalSrc.value = null
        modalVisible.value = false
        return;
    }
    output.value = event.detail.dest;
    outputSrc.value = URL.createObjectURL(event.detail.dest);
    emit('addedImage', event.detail.dest)
    modalSrc.value = null
    //uploadFieldModel.value = null
    modalVisible.value = false
}
const imageWriterProps = ref({
    quality: 1,
})
if ( props.uploadUrl ) {
    //imageWriterProps.value.store = props.uploadUrl
}
if ( props.uploadUrl ) {
    const axios = inject('axios')

    imageWriterProps.value.store = (state, options, onprogress) =>
        new Promise((resolve, reject) => {
            const { dest } = state;

            // create a formdata object to send to the server
            const formData = new FormData();
            formData.append('dest', dest, dest.name);


            axios({
                method: 'post',
                url: props.uploadUrl,
                data: formData,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                state.store = response
                resolve(state)
            })
            .catch((response) => {
                //handle error
                reject(state)
            });

            /*
            // create a request object
            const request = new XMLHttpRequest();
            request.open('POST', props.uploadUrl);

            // show progress in interface
            request.upload.onprogress = onprogress;

            // catch errors
            request.onerror = () => reject('oh no something went wrong!');
            request.ontimeout = () => reject('oh no request timed out!');

            // handle success state
            request.onload = () => {
                if (request.status >= 200 && request.status < 300) {
                    // store request in state so it can be accessed by other processes
                    state.store = request;
                    resolve(state);
                } else {
                    reject('oh no something went wrong!');
                }
            };

            // start uploading the image
            request.send(formData);*/
        })
}


const editorProps = {
                imageReader: createDefaultImageReader(),
                imageWriter: createDefaultImageWriter({...imageWriterProps.value}),
                ...plugin_finetune_defaults,
                ...plugin_filter_defaults,
                ...markup_editor_defaults,
                imageCropAspectRatio: props.aspectRatio,
                /*imageCropMinSize: {
                    width: 1200,
                    height: 1200
                },*/
                locale: {
                    ...locale_en_gb,
                    ...plugin_crop_locale_en_gb,
                    ...plugin_finetune_locale_en_gb,
                    ...plugin_filter_locale_en_gb,
                    ...plugin_annotate_locale_en_gb,
                    ...markup_editor_locale_en_gb,
                },
            }


    defineExpose({
        output: previewSrc,
        remoteUpload
    })
</script>

<style lang="scss" scoped>
    ::v-deep(.p-card-header) {
        background: #fcfcfc;
        border-bottom: 1px solid #eee;
        padding: .5rem;
    }
    ::v-deep(.p-card-content) {
        padding: .4rem 0;
    }
    ::v-deep(.p-card-header) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .image-cropper-actions .p-button {
        white-space: nowrap;
        margin: 0;
    }
    .no-image-container {
        padding: 1.5rem 0;
    }
    .p-button-icon-only .p-button-icon {
        margin-right: 0!important;
    }
    .image-cropper-actions .p-button:not(.p-button-icon-only) .p-button-icon, .no-image-container .p-button:not(.p-button-icon-only) .p-button-icon {
        font-size: 16px;
        margin-right: 5px;
    }
    .image-cropper-field {
        display: flex;
        justify-content: center;
        position: relative;
        &:hover {
            .image-size-info {
                opacity: 1;
            }
        }
    }
    .image-size-info {
        position: absolute;
        font-size: 10px;
        pointer-events: none;
        background: #ffffff;
        padding: 3px 10px;
        bottom: 0px;
        left: 0px;
        opacity: 0;
        transition: opacity .3s ease;
        z-index: 2;
        border-radius: 0 4px 0 0;
    }
</style>
