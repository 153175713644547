<template>
    <div :class="parentClass" @trigger="clickedOutside" class="row-menu-wrapper" ref="toggleParent">


        <tippy theme="wg-inline" placement="top" :offset="[0, -1]" v-for="(action, idx) in visibleMenuItems" :key="idx">
        <Button class="row-action p-button-icon-only p-button-rounded p-button-text"
                :class="action.color ? `p-button-${action.color}` : null" @mouseenter="hovering = idx" @mouseleave="hovering = -1"


                @click="triggerAction(action)"
                >

            <span v-if="action.iconHover && hovering == idx">
                <span class="iconify" :data-icon="action.iconHover" ></span>
            </span>
            <span v-else>
                <span class="iconify" :data-icon="action.icon"></span>
            </span>

        </Button>
        <template #content>
                <span v-html="action.label"></span>
            </template>
        </tippy>


    </div>
</template>
<script setup>


    import Button from 'primevue/button';
    import { useToggle } from '@vueuse/core';
    import { computed, onMounted, ref } from 'vue'
import { Inertia } from '@inertiajs/inertia';


    const props = defineProps({
        menuItems: {
            type: Array,
        },
        row: {
            type: Object
        },
        index: {
            type: [String, Number],
            default: "0"
        }
    })


    const menuRef = ref(null)
    const menuOpen = ref(false)

    const hovering = ref(false)

    const toggleParent = ref(null)

    const visibleMenuItems = computed( () => {
        return props.menuItems.filter( (action) => {
            if ( typeof action.visible == 'undefined' ) return true
            return !!action.visible
        })
    })

    const linkAttrs = (link) => {

        if ( !!link.action ) {
            return { 'v-bind:click': link.action }
        } else {
            return { href: link.href }
        }

    }

    const getIcon = (action, index) => {
        if ( !!action.iconHover && hovering.value == index ) {
            console.log('one is hovering', index)
            return action.iconHover
        }
        return action.icon
    }

    const toggleMenu = (event) => {
        menuRef.value.toggle(event)
    }

    const triggerAction = (action) => {

        if ( !!action.click) action.click()
        if ( !!action.target && !!action.href ) {
            if ( action.target == '_blank' ) {
                window.open( action.href, '_blank');
                return
            }
        }
        if ( !!action.href ) Inertia.visit(action.href)
    }

    const parentClass = computed( () => {
        return `row-actions-${props.index}`
    })

    onMounted( () => {

    })


</script>
<style lang="scss">
    .row-menu-wrapper {
        display: flex;
        align-items: center;
    }
</style>
