<template>
    <div class="spotlight-link">

        <div class="link-type-selector" v-if="!link.type">
            <div class="set-type-wrapper" :class="{ 'has-error': $v.type.$error }">
                <Dropdown :options="blockTypes" placeholder="Choose Link Type" @change="setType($event)">
                    <template #option="slotProps">
                        Add {{ slotProps.option.label }}
                    </template>
                </Dropdown>
            </div>
            <span class="link-type-error" severity="error" v-if="$v.type.$error">A link is required</span>
        </div>



        <div class="link-meta" v-if="link.type">

            <div class="link-body">
                <span class="link-meta-type" v-html="link.type"></span>

                <h3 class="link-title" v-html="link.title"></h3>
                <div class="link-fields" v-if="link.type == 'custom'">


                    <div class="form-field url-field">
                        <TextField :validator="$v.url" v-model="link.url" name="url" placeholder="Enter a url...">
                            <span class="iconify" data-icon="ph:link-bold"></span>
                        </TextField>
                    </div>

                    <div class="link-settings">
                        <div class="p-field-checkbox">
                            <Checkbox v-model="target" name="target" :true-value="'_blank'" :false-value="'_self'" :binary="true" />
                            <label for="target">Open in New Tab</label>
                        </div>
                    </div>

                </div>

                <div class="video-link" v-if="link.type == 'video' && link.id">
                    <span class="iconify" data-icon="ph:youtube-logo-fill"></span>
                    <a target="_blank" :href="`https://youtu.be/${link.id}`" v-if="link.provider == 'youtube'">youtu.be/{{link.id}}</a>
                    <a target="_blank" :href="`https://vimeo.com/${link.id}`" v-if="link.provider == 'vimeo'">vimeo.com/{{link.id}}</a>
                </div>

                <div class="event-link" v-if="link.type == 'event'">
                    <h5 v-html="link.event.all_dates"></h5>
                    <h6 v-html="link.event.city+', '+link.event.state"></h6>
                </div>

                <div class="venue-link" v-if="link.type == 'venue'">

                    <h5 v-html="link.venue.city+', '+link.venue.state"></h5>
                </div>
            </div>


            <div class="link-controls">
                <Button class="p-button-xs p-button-text p-button-flex" @click="resetType">
                    <span class="iconify" data-icon="ph:x-bold"></span>
                    <span class="reset-link-text">RESET</span>
                </Button>
            </div>

        </div>


        <teleport to="body">
            <div class="link-modals" v-if="!link.type">
                <ew-post-modal ref="postModal" @closed="modalClosed" @item-selected="callbacks.postSelected"></ew-post-modal>
                <ew-event-modal ref="eventModal" @item-selected="callbacks.eventSelected"></ew-event-modal>
                <ew-venue-modal ref="venueModal" @item-selected="callbacks.venueSelected"></ew-venue-modal>
                <parse-video-modal ref="videoModal" @video-selected="callbacks.videoSelected"></parse-video-modal>
            </div>
        </teleport>
    </div>
</template>
<script setup>

    import useVuelidate from '@vuelidate/core'
    import { minLength, required, helpers, url, requiredIf } from '@vuelidate/validators'
    import { computed, ref } from 'vue'

    import Chance from 'chance'

    const chance = new Chance();

    const emit = defineEmits(['update:modelValue', 'set-image'])

    const props = defineProps({
        modelValue: {
            type: Object
        },
        index: {
            type: Number,
            default: 0
        },
        imageEmit: {
            type: Boolean,
            default: false
        },
        everwondrOnly: {
            type: Boolean,
            default: false
        }
    })

    const postModal = ref()
    const venueModal = ref()
    const eventModal = ref()
    const videoModal = ref()
    const tourModal = ref()

    const target = computed({
        get: () => link.value.target == '_blank' ? true : false,
        set: (value) => link.value.target = value == true ? '_blank'  : '_self'
    })

    const ewPrefix = 'https://api.everwondr.com'

    const resetType = () => {
        link.value = {}
    }

    const getLinkType = () => {
        return link.value.label
    }

    const preload = ref(null)

    const modalClosed = () => {
        alert('true')
    }

    const blockTypes = ref([
        {
            type: 'post',
            label: 'Blog',
            fields: {
                title: '',
                image: null,
                url: '',
                post: null
            }
        },
        {
            type: 'event',
            label: 'Event',
            fields: {
                title: '',
                image: null,
                url: '',
                event: null
            }
        },
        {
            type: 'venue',
            label: 'Directory',
            fields: {
                title: '',
                image: null,
                url: '',
                venue: null
            }
        },

    ])

    if (!props.everwondrOnly) {
        blockTypes.value.push({
            type: 'video',
            label: 'Video',
            fields: {
                title: '',
                image: null,
                video: {}
            }
        })
        blockTypes.value.push({
            type: 'custom',
            label: 'Custom',
            fields: {
                title: '',
                image: null,
                url: '',
                target: '_blank'
            }
        })
    }

    const link = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const itemType = computed( () => {
        return link.value.type
    } )

    const itemTypeIs = (candidate) => {
        return itemType.value == candidate
    }

    const setType = (event) => {

        const val = event.value;

        if ( ['custom'].includes(val.type) ) {
            link.value = {
                type: 'custom',
                title: '',
                subtitle: '',
                target: '_blank',
                uuid: chance.string({length: 12}),
                url: null
            }
        }
        if ( val.type == 'post' ) {
            postModal.value.show(  )
        }
        if ( val.type == 'event' ) {
            eventModal.value.show(  )
        }
        if ( val.type == 'venue' ) {
            venueModal.value.show(  )
        }
        if ( val.type == 'video' ) {
            videoModal.value.show(  )
        }
    }

    const callbacks = {

        postSelected: (post) => {
            //target.title = post.title
            //target.object = post

            let obj = {
                type: 'post',
                url: '',
                title: post.title,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                post: {
                    url_title: post.url_title,
                    street_address: post.address_1,
                    city: post.city,
                    state: post.state,
                    zip: post.zip,
                    lat: post.lat,
                    lng: post.lng,
                    summary: post.summary,
                    first_name: post.first_name,
                    last_name: post.last_name
                }
            }

            link.value = {...obj}


            if (!props.imageEmit) return;
            let photos = post.uploads.filter( (upload) => {
                return upload.type == 'photo'
            })
            if ( photos.length ) {
                emit('set-image', ewPrefix+photos[0].source)
            }

        },
        eventSelected: (event) => {
            let obj = {
                type: 'event',
                url: '',
                title: event.name,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                event: {
                    url_title: event.url_title,
                    street_address: event.address_1,
                    city: event.city,
                    state: event.state,
                    zip: event.zip,
                    lat: event.lat,
                    lng: event.lng,
                    next_date: event.next_date,
                    all_dates: event.all_dates,
                    summary: event.trunc_description
                }
            }


            link.value = {...obj}

            if (!props.imageEmit) return;
            if ( !!event.thumbnail ) {
                emit('set-image', ewPrefix+event.thumbnail)
            }

        },
        venueSelected: (venue) => {
            let obj = {
                type: 'venue',
                url: '',
                title: venue.name,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                venue: {
                    url_title: venue.url_title,
                    street_address: venue.address_1,
                    city: venue.city,
                    state: venue.state,
                    zip: venue.zip,
                    lat: venue.lat,
                    lng: venue.lng,
                    phone: venue.phone,
                    email: venue.email,
                    types: venue.venue_types
                }
            }
            link.value = {...obj}

            if (!props.imageEmit) return;
            if ( !!venue.thumbnail ) {
                emit('set-image', ewPrefix+venue.thumbnail)
            }

        },
        videoSelected: (video) => {
            console.log('VIDEO', video.value)

            let obj = {
                type: 'video',
                url: '',
                title: video.value.title,
                layout: 'horizontal',
                uuid: chance.string({length: 12}),
                image: video.value.thumbnail,
                id: video.value.id,
                provider: video.value.provider,
            }


            console.log('VIDEO PARSED', video)

            link.value = {...obj}

        }

    }


    const rules =
        {
            //image: { required: helpers.withMessage('An image is required', required)},
            type: { requred: required },
            url: {
                url: helpers.withMessage('Must be a valid URL', url),
                requiredIf: requiredIf( itemTypeIs('custom') )
            }
        }

    const $v = useVuelidate(rules, link, { $registerAs: `link` })

</script>
<style lang="postcss" scoped>
.link-meta {
    width: 100%;
}
    .spotlight-link {
        padding: 5px;
        border-radius: 0 0 6px 6px;
        background: #f8f8f8;
        border-top: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
        min-height: 70px;
        justify-content: center;

        .link-type-selector {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .set-type-wrapper {
                display: flex;
                justify-content: center;
                &.has-error {
                    ::v-deep(.p-dropdown) {
                        border-bottom-color: #f44336;
                    }
                }
                ::v-deep(.p-dropdown) {
                    border: 2px solid #eee;
                }
                ::v-deep(.p-dropdown-label) {
                    font-size: 0.875rem;
                    padding: 0.6125rem 0.525rem;
                    font-weight: 600;
                }
            }
            .link-type-error {
                font-size: 11px;
                color: #f44336;
                margin-top: 6px;
                font-weight: 600;
            }
        }

        .link-body {
            padding: 5px;

            .link-meta-type {
                background: #03A2DC;
                padding: 0.3475rem 0.775rem;
                color: #ffffff;
                font-size: 11px;
                text-transform: uppercase;
                border-radius: 6px;
                display: inline-block;
                font-weight: 600;
                margin-bottom: 8px;
            }

            .video-link {
                display: flex;
                align-items: center;
                background: #f4f4f4;
                border-radius: 5px;
                padding: 0.3975rem 0.775rem;
                font-size: 18px;
                line-height: 1em;
                box-shadow: 1px 1px 0 1px #ececec;
                color: #03A2DC;
                a {
                    font-size: 12px;
                    margin-left: 4px;
                    font-weight: 600;
                    color: #777;
                }
            }

            .event-link {
                h5 {
                    font-size: 13px;
                    margin: 0 0 3px 0;
                    color: #888;
                }
                h6 {
                    font-size: 12px;
                    margin: 0;
                }
            }

            .venue-link {
                h5 {
                    font-size: 13px;
                    margin: 0 0 3px 0;
                    color: #888;
                }
                h6 {
                    font-size: 12px;
                    margin: 0;
                }
            }

            .link-meta {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }

            .link-settings {
                font-size: 12px;
                margin-top: 10px;
            }

            .link-title {
                font-size: 15px;
                margin: 0 0 8px 0;
            }
        }

        .link-controls {
            display: flex;
            justify-content: flex-end;

            .reset-link {
                font-size: .75rem;
                color: #ffffff;
                display: flex;
                align-items: center;

            }
            .p-button-flex {
                display: flex;
                align-items: center;

            }
            .p-button-xs {
                font-size: 13px;
                padding: 0.3475rem 0.775rem;
                .reset-link-text {
                    margin-left: 3px;
                    font-size: 11px;
                }
            }
        }

    }


</style>
