<template>
    <div class="account-guide-selector">

        <div class="form-box-container">
            <div class="form-box-title">
                <span class="iconify" data-icon="ph:compass-bold"></span>
                <h3>Accounts</h3>
            </div>
            <div class="form-box-body">

                <div class="form-dropdown-field">
                    <MultiSelect class="p-full" :class="{ 'p-invalid': $v.accounts.$error }" v-model="modelAccounts" :show-toggle-all="false" :options="accounts" optionLabel="name" dataKey="id" placeholder="Select Accounts" display="chip">
                    </MultiSelect>
                    <small v-if="$v.accounts.$error" class="p-error">At least one account is required.</small>
                </div>

            </div>
        </div>

        <div class="form-box-container">
            <div class="form-box-title">
                <span class="iconify" data-icon="ph:compass-bold"></span>
                <h3>Guides</h3>
            </div>
            <div class="form-box-body">

                <div class="form-dropdown-field">
                    <MultiSelect class="p-full" :class="{ 'p-invalid': $v.guides.$error }" v-model="modelGuides" :show-toggle-all="false" :options="filteredGuides" optionLabel="name" optionGroupLabel="name" optionGroupChildren="items" dataKey="id" placeholder="Select Guides" display="chip">
                        <template #option="slotProps">

                            <span>{{ slotProps.option.name }}</span>

                        </template>
                        <template #chip="slotProps">
                            <span class="guide-label">
                                <span class="guide-account-name" v-html="getGuideAccountLabel(slotProps.value)"></span>
                                <span class="guide-name">{{ slotProps.value.name }}</span>
                            </span>
                        </template>
                    </MultiSelect>
                    <small v-if="$v.guides.$error" class="p-error">At least one guide is required.</small>
                </div>

            </div>
        </div>

    </div>
</template>

<script setup>

    import { computed, onMounted, ref, watch } from 'vue'
    import { pluck, reduce, flatten } from 'underscore'

    import { email, helpers, minLength, required, sameAs, and, url, requiredIf, requiredUnless } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'

    const props = defineProps({
        modelValue: {
            type: Object,
            default: []
        },
        user: {
            type: Object,
            default: null
        },
        accounts: {
            type: Array,
            default: []
        },
        guides: {
            type: Array,
            default: []
        }
    })

    const allGuides = computed( () => {
        return flatten( props.accounts.map( (account) => {
            return account.guides
        }) );
    })

    const filteredGuides = computed(() => {

        return modelAccounts.value.map((account) => {
            return {
                name: account.name,
                code: account.id,
                items: props.guides.filter( (guide) => {
                    return guide.account_id == account.id
                } )
                /*items: account.guides.map((guide) => {
                    return guide
                })*/
            }
        })
    })
    const pruneGuideSelections = () => {
        let accounts = pluck(model.value.accounts, 'id');
        let updatedGuides = model.value.guides.filter( (guide) => {
            return accounts.includes( guide.account_id )
        })
        model.value.guides = updatedGuides
    }
    const guideAccount = (guide) => {
        let account = model.value.accounts.find((acct) => {
            return guide.account_id == acct.id
        })
        if ( account ) {
            return account.name
        } else {
            return null
        }
    }

    onMounted( () => {
        watch(
            () => [ ...model.value.accounts ],
            (newAccounts, oldAccounts) => {
                pruneGuideSelections()
            },
            { immediate: true }
        )
    })




    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const modelAccounts = computed({
      get: () => {

            return model.value.accounts.filter( (account) => {
                return props.accounts.find( (userAccount) => {
                    return userAccount.id == account.id;
                })
            })
      },
      set: (value) => {

        props.accounts.forEach( (userAccount) => {
            let pendingAccount = value
                .find( account => account.id == userAccount.id )

            if ( pendingAccount ) {
                let addIndex = model.value.accounts.findIndex( (modelAccount) => {
                    return userAccount.id == modelAccount.id
                })
                if ( addIndex == -1 ) {
                    model.value.accounts.push( pendingAccount )
                }
            } else {
                let removeIndex = model.value.accounts.findIndex( (modelAccount) => {
                    return userAccount.id == modelAccount.id
                })
                if ( removeIndex > -1 ) {
                    model.value.accounts.splice(removeIndex, 1)
                }
            }
        })
      }
    })

    const getGuideAccountLabel = (guide) => {
        let guideAccount = props.accounts.find( (account) => {
            return account.id == guide.account_id
        } )
        if (guideAccount) {
            return `${guideAccount.name}:`
        } else {
            return false
        }
    }

    const modelGuides = computed({
      get: () => {

            return model.value.guides.filter( (guide) => {
                return allGuides.value.find( (userGuide) => {
                    return userGuide.id == guide.id;
                })
            })
      },
      set: (value) => {
        allGuides.value.forEach( (userGuide) => {
            let pendingGuide = value.find( guide => guide.id == userGuide.id )

            if ( pendingGuide ) {

                let addIndex = model.value.guides.findIndex( (modelGuide) => {
                    return userGuide.id == modelGuide.id
                })
                if ( addIndex == -1 ) {
                    model.value.guides.push( pendingGuide )
                }
            } else {
                let removeIndex = model.value.guides.findIndex( (modelGuide) => {
                    return userGuide.id == modelGuide.id
                })
                if ( removeIndex > -1 ) {
                    model.value.guides.splice(removeIndex, 1)
                }
            }
        })
      }
    })



    const rules =
        computed( () =>
        ({
            guides: { required: helpers.withMessage('Please select at least one guide', required ) },
            accounts: { required: helpers.withMessage('Please select at least one account', required) },
        }))


    const $v = useVuelidate(rules, model)


</script>
<style scoped>
    .p-full {
        width: 100%;
    }
</style>
