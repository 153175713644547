<template>
    <div class="layout-breadcrumbs">


        <div class="breadcrumbs">
            <div class="breadcrumbs-left">
            <Link :href="crumb.href" v-for="(crumb, idx) in breadcrumbs" :class="{ 'active': idx == breadcrumbs.length-1 }" :key="'bc-'+idx" v-html="crumb.title"></Link>
            </div>

            <div class="breadcrumbs-right" v-if="$slots.button">
            <slot name="button"/>
            </div>
        </div>

    </div>
</template>
<script setup>


    import { computed, ref } from 'vue'
    import { usePage } from '@inertiajs/inertia-vue3'

    const props = defineProps({
        breadcrumbs: {
            type: [Array, Boolean],
            default: false
        }
    })

    const curRoute = usePage().url.value
//test
    /*const breadcrumbs = computed( () => {

        if ( props.custom ) {
            return props.custom;
        } else {
            let parts = usePage().url.value.split('/')
            let ret = [];

            let url = []
            parts.forEach( (item, idx) => {
                if ( item.length ) {
                    url.push(item);
                    ret.push({
                        href: `/${url.join('/')}`,
                        title: item
                    })
                }
            })
            return ret
        }

    })*/

</script>
