<template>
  <vue-final-modal v-model="visible" @closed="closed" v-bind="$attrs" classes="modal-container" content-class="modal-content" transition="modal-open">

    <span class="modal__title" v-html="settings.title">

    </span>
    <div class="modal__content" v-if="settings.message">
        <div v-html="settings.message"></div>
    </div>

    <div class="modal__action">
        <Button @click="onConfirm" class="p-button-sm p-button-rounded p-button-success">
            <span class="p-button-label">Confirm</span>
        </Button>
        <Button @click="onCancel" v-if="settings.showCancel" class="p-button-sm p-button-text p-button-rounded p-button-danger p-cancel-button">
            <span class="iconify p-button-icon" data-icon="ph:x-circle-fill"></span>
            <span class="p-button-label">Cancel</span>
        </Button>
    </div>

    <Button class="modal__close p-button-icon-only p-button-rounded p-button-danger" @click="onCancel" v-if="settings.showClose">
      <span class="iconify" data-icon="ph:x-bold"></span>
    </Button>
  </vue-final-modal>
</template>

<script setup>
import { ref } from "vue"


const emit = defineEmits(['confirm', 'cancel'])


const defaults = {
    title: "Confirm Action",
    message: false,
    showCancel: true,
    showClose: false,
    onConfirm: () => {},
    onCancel: () => {}
}

const settings = ref({...defaults})

const visible = ref(false)

const show = (opts = {}) => {
    settings.value = { ...defaults, ...opts }

    visible.value = true
}
const hide = () => {
    visible.value = false
}

const onConfirm = () => {
    settings.value.onConfirm()
    hide()
    emit('confirm')

}

const onCancel = () => {
    settings.value.onCancel()
    hide()
    emit('cancel')
}

const closed = () => {
    settings.value = { ...defaults }
}

defineExpose({
    hide,
    show
})

</script>
<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;

}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;

  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  min-width: 400px;
  max-width: 600px;
}

.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1.1rem 1rem 0;
}
.modal__close.p-button.p-button-icon-only.p-button-rounded {
    width: 2rem;
    height: 2rem;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.4rem 1rem;
}
.modal__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: .875rem;
  border-top: 2px solid #eeeeee;
  background: #f8f8f8;
}
.modal__action .p-button {
    display: flex;
    align-items: center;
}
.modal__action .p-button .p-button-icon {
    font-size: 1rem;
    margin-right: 3px;
}
.p-cancel-button {
    margin-left: .5rem;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
