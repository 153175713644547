<template>
    <div class="block-editor" :class="{ 'is-dragging': draggingBlock }">


        <draggable
            :list="rows"
            group="rows"
            class="block-rows"
            handle=".row-move-button"
            ghost-class="ghost-block"
            @start="enableDrag"
            @end="disableDrag"
            item-key="uuid">
            <template #item="{element: row, index}">

                <div class="block-row">

                    <a class="remove-row" @click="removeRow(index)">
                        <span class="iconify" data-icon="ph:x-bold"></span>
                    </a>
                    <a class="row-move-button">
                        <span class="iconify" data-icon="ph:arrows-out-line-vertical-bold"></span>
                    </a>

                    <AirEditor v-if="row.type == 'html'" v-model="row.content"></AirEditor>

                    <GridBlock v-if="row.type == 'grid'" v-model="rows[index]"></GridBlock>
                    <BlockQuote v-if="row.type == 'blockquote'" v-model="rows[index]"></BlockQuote>
                </div>

            </template>
        </draggable>
        <!--<div class="block-row" v-for="(row, idx) in rows" :key="idx">

            <a class="remove-row" @click="removeRow(idx)">
                <span class="iconify" data-icon="ph:x-bold"></span>
            </a>

            <ckeditor v-if="row.type == 'html'" :editor="editor" v-model="row.content" :config="editorConfig"></ckeditor>

            <GridBlock v-if="row.type == 'grid'" v-model="rows[idx]"></GridBlock>
            <BlockQuote v-if="row.type == 'blockquote'" v-model="rows[idx]"></BlockQuote>
        </div>-->



        <BlockSelector @insert="insertRow"></BlockSelector>



    </div>
</template>
<script setup>
    import { computed, onMounted, inject, ref, provide } from "vue"

    import { usePage } from '@inertiajs/inertia-vue3'

   // import Editor from '../../Libraries/ckeditor/build/ckeditor.js'
    //import '../../Libraries/ckeditor/build/translations/en-gb';
    //import randomstring from 'randomstring'

    const emit = defineEmits(['update:modelValue'])

   // const editor = Editor;


    import ImagesBlock from './Blocks/Images.vue'
    import GridBlock from './Blocks/Grid.vue'
    import BlockQuote from './Blocks/BlockQuote.vue'

    import BlockSelector from  './Partials/BlockSelector.vue'

    const props = defineProps({
        modelValue: {
            type: Array,
            default: []
        }
    })

    const chance = inject('chance')

    const draggingBlock = ref(false)

    provide('draggingBlock', draggingBlock)

    const enableDrag = () => {
        draggingBlock.value = true
    }
    const disableDrag = () => {
        setTimeout( () => {
            draggingBlock.value = false
        }, 400)
    }



    const editorConfig = ref({
        placeholder: 'Click here to start typing...',
        simpleUpload: {
            // The URL that the images are uploaded to.
            uploadUrl: '/admin/upload/editor',

            // Enable the XMLHttpRequest.withCredentials property.
            withCredentials: true,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                'X-CSRF-TOKEN': usePage().props.value.csrf_token,

            }
        },
        image: {
            toolbar: [
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:side',
                '|',
                'toggleImageCaption',
                'imageTextAlternative',
                'linkImage'
            ]
        },
        fontFamily: {
            options: [
                'default',
                'Playfair Display, Didot, sans-serif',
                'Dosis, sans-serif',
                'Georgia, serif',
            ]
        },
        fontSize: {
            options: [
                10,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: {
            shouldNotGroupWhenFull: true,
        },
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading3', view: 'h3', title: 'Large Header', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Small Header', class: 'ck-heading_heading4' },
                { model: 'heading5', view: { name: 'h5', classes: 'subheader' }, title: 'Subheader', class: 'ck-heading_heading5_subheader' }
            ]
        }
    })

    const rows = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    onMounted( () => {

        rows.value.forEach((row) => {

            if ( !row.uuid ) {
                row.uuid = chance.string({ length: 8 })
            }
            //console.log()
            if ( row.type == 'grid' ) {
                row.columns.forEach( (column) => {

                    column.blocks.forEach( (block) => {
                        if ( !block.uuid ) {
                            block.uuid = chance.string({ length: 8 })
                        }
                    })


                } );
            }
        })




    } )

    const insertRow = (row) => {
        row.uuid = chance.string({ length: 8 })
        rows.value.push(row)
    }

    const removeRow = (idx) => {
        rows.value.splice(idx, 1)
    }

</script>
<style lang="postcss">
    @import '@/css/_fonts.css';
    :root {
        --ck-inner-shadow: none!important;
        --ck-focus-ring: none;
        --ck-spacing-standard: 0;
        --ck-color-image-caption-background: none;
    }
    .block-editor {
        margin: 1.5rem 0;
        display: grid;
        gap: 1.5rem;
        .block-rows {
            display: grid;
            gap: 1.5rem;
        }
    }

    .ck-content {
        font-family: 'Dosis', sans-serif;
        //font-size: 13px;
        color: #1c2522;
        font-size: 1.15rem;
        :where(h1, h2, h3, h4, h5, h6) {
            //margin: 0 0 1.3rem 0!important;
            font-family: 'Playfair Display', 'Georgia', serif;
            margin: 0 0 1rem;
        }
        p, ul {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        h3 {
            font-size: 1.4rem;

        }
        h4 {
            font-size: 1.25rem;

        }
        h5 {
            font-size: 1rem;
        }
        h4 + h5.subheader, h3 + h5.subheader {
            margin-top: -.8rem;
            color: rgb(50, 58, 55);
        }
        *:first-child {
            margin-top: 0!important;
        }
        *:last-child {
            margin-bottom: 0!important;
        }
        .image.image_resized {
            display: block;
            box-sizing: border-box;
        }

        .image.image_resized img {
            width: 100%;
        }

        .image.image_resized > figcaption {
            display: block;
        }
        figure.media {
            margin: .9em auto;
            max-width: 580px;
        }
        figure.image {
            img {
                border-radius: 4px;

            }
        }
    }


    .block-row {
        position: relative;

        /*&:after {
            content: '';
            display: block;
            position: absolute;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            top: -5px;
            left: -5px;
            box-shadow: 0 0 0 2px rgba(0,0,0,.05);
            pointer-events: none;
            z-index: -1;
            opacity: 0;
            transition: opacity .2s ease;
        }*/
        &:hover {
            z-index: 1;
            .remove-row {
                opacity: .6;
                &:hover {
                    opacity: 1;
                    background: #D32F2F;
                    color: #fff;
                }
            }
            .row-move-button {
                opacity: .6;
                &:hover {
                    opacity: 1;
                    background: #777;
                    color: #fff;
                }
            }
            &:after {
                opacity: 1;
            }
        }
        .remove-row {
            position: absolute;
            z-index: 4;
            top: 0;
            right: -21px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(211, 47, 47, 0.08);
            border-radius: 3px;
            color: #D32F2F;
            opacity: 0;
            transition: opacity .3s ease, color .3s ease, background .3s ease;
        }
        .row-move-button {
            position: absolute;
            z-index: 4;
            top: 23px;
            right: -21px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(211, 47, 47, 0.08);
            border-radius: 3px;
            color: #555;
            opacity: 0;
            transition: opacity .3s ease, color .3s ease, background .3s ease;
        }
    }
</style>
