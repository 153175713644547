<template>
    <div class="blockquote">
        <div class="blockquote-bg">
            <img src="data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1s bnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJ IHZpZXdCb3g9IjAgMCAyMTcuMiAxNjAuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAw IDAgMjE3LjIgMTYwLjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQv Y3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojRjJGM0Y0O3N0cm9rZS13aWR0aDozO3N0cm9r ZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+CjxwYXRoIGNs YXNzPSJzdDAiIGQ9Ik02NC40LDEyNy42YzguMS05LjQsMTMtMTguOSwxNC42LTI4LjdjMC45LTUu MSwxLjQtOS42LDEuNC0xMy4yYzAtNC4yLTAuNS03LjctMS40LTEwLjUKCWMtOC42LDktMTkuNiwx My41LTMyLjgsMTMuNWMtMTMuMiwwLTI0LTMuOS0zMi4zLTExLjZjLTguMy03LjctMTIuNC0xOC40 LTEyLjQtMzJjMC0xMi45LDQuNC0yMy40LDEzLjEtMzEuNAoJQzIzLjMsNS41LDM0LjQsMS41LDQ3 LjgsMS41YzE4LjQsMCwzMS44LDYuNiw0MC4zLDE5LjljMy45LDUuNyw2LjksMTIuNyw5LjEsMjEu MWMyLjIsOC40LDMuMywxNywzLjMsMjUuOGMwLDE5LjMtNSwzNy0xNC45LDUzCgljLTkuOSwxNi0y NC43LDI4LjUtNDQuNCwzNy41TDM2LDE0OC41QzQ2LjgsMTQzLjksNTYuMywxMzYuOSw2NC40LDEy Ny42eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTc5LjUsMTI3LjZjOC4xLTkuNCwxMy0xOC45 LDE0LjYtMjguN2MwLjktNS4xLDEuNC05LjYsMS40LTEzLjJjMC00LjItMC41LTcuNy0xLjQtMTAu NQoJYy04LjYsOS0xOS42LDEzLjUtMzIuOCwxMy41Yy0xMy4yLDAtMjQtMy45LTMyLjMtMTEuNmMt OC4zLTcuNy0xMi40LTE4LjQtMTIuNC0zMmMwLTEyLjksNC40LTIzLjQsMTMuMS0zMS40CgljOC43 LTguMSwxOS44LTEyLjEsMzMuMi0xMi4xYzE4LjQsMCwzMS44LDYuNiw0MC4zLDE5LjljMy45LDUu Nyw2LjksMTIuNyw5LjEsMjEuMWMyLjIsOC40LDMuMywxNywzLjMsMjUuOGMwLDE5LjMtNSwzNy0x NC45LDUzCgljLTkuOSwxNi0yNC43LDI4LjUtNDQuNCwzNy41bC01LjItMTAuMkMxNjIsMTQzLjks MTcxLjQsMTM2LjksMTc5LjUsMTI3LjZ6Ii8+Cjwvc3ZnPg==">
        </div>
        <div class="blockquote-body">
            <ContentEditable class="blockquote-input" tag="div" :contenteditable="true" v-model="blockquote.content" :noHTML="true" />
        </div>
    </div>
</template>
<script setup>
    import { ref, computed } from 'vue'
    import { helpers, required } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'



    const emit = defineEmits('update:modelValue')
    const props = defineProps({
        modelValue: {
            default: {}
        }
    })

    const blockquote = computed({
        get: () => props.modelValue,
        set: (value) => emit('update:modelValue', value)
    })

    const rules =
        computed( () =>
        ({

           content: { required: helpers.withMessage('Please enter a quote', required ) },

        }))

    const $v = useVuelidate(rules, blockquote)

</script>
<style lang="postcss" scoped>

    .blockquote {
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding: 0 .6rem;
        .blockquote-bg {


            -webkit-text-stroke: 2px #eeeeee;
            width: 100%;
            text-stroke: 2px #eeeeee;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            min-height: 100px;
            max-height: 200px;
            height: 100%;
            padding: 20px 0;
            pointer-events: none;
            z-index: -1;
            img {
                object-fit: cover;
                height: 100%;
                width: auto;
            }
        }
        .blockquote-body {
            width: 100%;
            padding: 3.5rem 0;
            ::v-deep(.blockquote-input) {
                border: none;
                outline: none;
                box-shadow: none!important;
                background: none;
                font-family: 'Playfair Display', serif;
                font-style: italic;
                width: 100%;
                font-size: 1.6rem;
                letter-spacing: .02rem;
                color: #444;
                padding: 0 2rem;
                max-width: 560px;
                font-weight: 500;
            }
        }

    }



</style>
