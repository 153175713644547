<template>

    <div class="item-block" :class="{'uploading-file': uploadingFile }">
        <transition name="fade">
            <div class="uploading-overlay" v-if="uploadingFile">
                <div class="loader"><div></div><div></div></div>
            </div>
        </transition>
        <span class="item-type" v-text="inputValue.type"></span>
        <a class="remove-item" @click="emit('removeBlock')">
            <span class="iconify" data-icon="ph:x-bold"></span>
        </a>

        <slot name="sort"></slot>

        <div class="item-image">
            <UploadCrop
                :ref="uploaderRef"
                name="upload"
                :aspect-ratio="false"
                preview-width="100%"
                height="120px"
                preview-padding="0"
                :image="inputValue.image"
                v-model="inputValue.image"
                :error="$v.image.$error"
                label="Image"
                error-message="A image upload is required"
                :choose-label="false"
                upload-url="/admin/upload/single"
                >
            </UploadCrop>
        </div>

        <div class="item-body">
            <Textarea @blur="$v.title.$touch()" class="block-title" placeholder="Enter a title" v-model="inputValue.title" rows="1" :autoResize="true" :class="{'p-invalid': $v.title.$error }" />

            <div class="block-field" v-if="inputValue.type == 'custom'">
                <TextField class="block-input p-inputtext-sm"  :validator="$v.url" v-model="inputValue.url" name="url" placeholder="Enter a url">
                    <span class="iconify" data-icon="ph:link-bold"></span>
                </TextField>
            </div>
            <div class="event-dates" v-if="inputValue.type == 'event' && inputValue.object">
                {{ inputValue.object.next_date }}
            </div>
            <div class="venue-location" v-if="inputValue.type == 'venue' && inputValue.object">
                {{ inputValue.object.city }}, {{ inputValue.object.state }}
            </div>
            <div class="post-author" v-if="inputValue.type == 'post' && inputValue.object">
                <template v-if="inputValue.object.first_name">
                <i>by</i> {{ inputValue.object.first_name }} {{ inputValue.object.last_name }}
                </template>
                <template else>
                    {{ inputValue.object.city }}, {{ inputValue.object.state }}
                </template>
            </div>
            <div class="video-link" v-if="inputValue.type == 'video' && inputValue.object">
                <span class="iconify" data-icon="ph:youtube-logo-fill"></span>
                <a target="_blank" :href="`https://youtu.be/${inputValue.object.id}`" v-if="inputValue.object.provider == 'youtube'">youtu.be/{{inputValue.object.id}}</a>
                <a target="_blank" :href="`https://vimeo.com/${inputValue.object.id}`" v-if="inputValue.object.provider == 'vimeo'">vimeo.com/{{inputValue.object.id}}</a>
            </div>

            <div class="pdf-upload" v-if="inputValue.type == 'pdf'">
                <span class="file-item" v-if="inputValue.file">
                    <span class="icon">
                        <span class="iconify" data-icon="ph:files-fill"></span>
                    </span>
                    <span class="text" v-html="getFilename(inputValue.file)"></span>

                    <span class="remove-icon" @click="removeFile">
                        <span class="iconify" data-icon="ph:x-circle-fill"></span>
                    </span>
                </span>
                <FileUpload @before-upload="uploadingFile = true"   v-if="!inputValue.file" choose-label="Choose File" :auto="true" mode="basic" name="file" url="/admin/upload/pdf" @upload="fileUploaded">
                </FileUpload>
                <span class="form-error-text" v-if="$v.file.$error">A file upload is required</span>
            </div>

        </div>

        <div class="item-layout">
            <div class="layout-options">
                <span
                    class="layout-option"
                    @click="inputValue.layout = 'horizontal'"
                    :class="{'selected':inputValue.layout == 'horizontal'}">
                    HORIZONTAL
                </span>
                <span
                    class="layout-option"
                    @click="inputValue.layout = 'vertical'"
                    :class="{'selected':inputValue.layout == 'vertical'}">
                    VERTICAL
                </span>
            </div>
        </div>

    </div>


</template>
<script setup>
    import { ref, computed, onMounted, inject } from 'vue'
    import { format, parseJSON, add } from 'date-fns'
    import { and, helpers, minLength, required, requiredIf, requiredUnless, url } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'

    const emit = defineEmits(['update:modelValue', 'removeBlock'])

    const ewPrefix = 'https://api.everwondr.com'

    const props = defineProps({
        modelValue: {
            type: Object,
            default: {}
        }
    })

    const uploaderRef = ref()

    const uploadingFile = ref(false)

    const draggingBlock = inject('draggingBlock')

    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    const ewTypes = ['venue', 'event', 'post']
    const isEwType = (blockType) => {
        return ewTypes.includes(blockType)
    }


    const getFilename = (path) => {
        let parts = path.split('/');
        return parts[ parts.length - 1 ]

    }
    const removeFile = () => {
        inputValue.value.file = null
    }

    const fileUploaded = ({xhr, files}) => {
        let res = JSON.parse(xhr.response)
        inputValue.value.file = res.path

        uploadingFile.value = false
    }


    onMounted( () => {

        if ( !inputValue.value.layout ) {
            inputValue.value.layout = 'horizontal'
        }

    } )

    const rules =
        computed( () =>
        ({

            title: { required: helpers.withMessage('A title is required', and(required, minLength(2) ) ) },
            image: { required: helpers.withMessage('An image is required', required) },
            url: { required: helpers.withMessage('A url is required', and( requiredIf(inputValue.value.type == 'custom'), url ))},
            file: { required: helpers.withMessage('A file is required', requiredIf(inputValue.value.type == 'pdf') )},
            object: { required: helpers.withMessage('A video is required', requiredIf(inputValue.value.type == 'video'))}

        }))

    const $v = useVuelidate(rules, inputValue, { $registerAs: 'related_item'})

</script>
<style lang="postcss" scoped>
    .pdf-upload {
        margin-top: .7rem;
        .p-inputtext-label {
            font-weight: 600;
            font-size: .9rem;
        }
        ::v-deep(.p-fileupload-choose.p-button) {
            padding: .4rem .75rem;
            display: flex;
            align-items: center;
            .p-button-label {
                font-size: .9rem;
            }
        }
    }
    .file-item {
        display: flex;
        align-items: center;
        border-radius: 1rem;
        background: #f1f1f1;
        height: 2rem;
        padding: 0 .2rem 0 .6rem;
        .icon {
            font-size: 1.1rem;
            margin-right: 4px;
            svg {
                display: block;
            }
        }
        .text {
            font-weight: 600;
            font-size: .9rem;
            margin-right: .4rem;
        }
        .remove-icon {
            color: #B91D1B;
            opacity: .75;
            transition: opacity .3s ease;
            font-size: 1.8rem;
            &:hover {
                opacity: 1;
            }
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            cursor: pointer;
        }
    }
    .uploading-overlay {
        background: rgba(255,255,255,.4);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        .loader {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }
        .loader div {
            position: absolute;
            border: 4px solid #eee;
            opacity: 1;
            border-radius: 50%;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }
        .loader div:nth-child(2) {
            animation-delay: -0.5s;
        }
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }

    .block-field {
        width: 100%;
    }

    .fade-enter-active  {
        transition: opacity 0.3s ease-out;
    }

    .fade-leave-active {
        transition: opacity .3s ease-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .item-layout {

        background: #fafafa;
        border-top: 1px solid #eee;
        width: 100%;
        padding: 8px;
        label {
                font-weight: 600;
                margin-bottom: 5px;
                font-size: .9rem;
            }
        .layout-options {
            display: flex;
            gap: 5px;

            .layout-option {
                display: flex;
                line-height: 1.6rem;
                font-size: .7rem;
                font-weight: 700;
                padding: 0 .8rem;
                border-radius: .8rem;
                cursor: pointer;
                transition: background .3s ease;

                .layout-image, .layout-body {
                    border-radius: 2px;
                    background: #ddd;
                }
                &:hover {
                    background:#f3f3f3;
                }
                &.selected {
                    background: #03A2DC;
                    color: #ffffff;
                }
            }
        }

    }





</style>
<style lang="scss">
    .item-block {
        padding-bottom: 18px;
        .drag-handle {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            left: 0;
            bottom: 0;
            color: #666;
            background: #f8f8f8;
            border-top: 1px solid #eee;
            border-radius: 0 0 5px 5px;

            height: 18px;
            cursor: grab;
            &:hover {
                background: #f3f3f3;
                color: #444;
            }
        }
    }
</style>
